import { env } from "./env";

const address = env.REACT_APP_CONST_API_URL || "http://localhost:8099";
const basename = env.PUBLIC_URL;
const TICKET_IP_ADDRESS = env.REACT_APP_TICKET_URL || "http://localhost:5000";
export default Object.freeze({
  IP_ADDRESS: address,
  BASENAME: basename,
  TICKET_IP_ADDRESS: TICKET_IP_ADDRESS,
});
