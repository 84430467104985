import React, { useEffect, useState } from "react";
import { FocusInputs, Form, Input, Tag } from "@alpaca/framework";
import { isEmailFormat } from "../../../pages/register/components/register-form/register-form.util";
import "./inviteUserForm.scss";

export default function InviteUserForm({ emails, onChange }) {
  const [form] = Form.useForm();

  const email = Form.useWatch("email", form);
  const [emailList, setEmailList] = useState([]);

  useEffect(() => {
    if (Array.isArray(emails)) {
      setEmailList(emails);
    }
  }, [emails]);

  return (
    <Form form={form} className="invite-user-form form">
      <Form.Item
        name="email"
        rules={[{ validator: onValidateEmail, validateTrigger: "none" }]}
      >
        <FocusInputs.Input
          autoComplete="one-time-code"
          title="Enter Email"
          onKeyDown={handleInputKeyDown}
        />
      </Form.Item>
      <div className="tag-field">
        {emailList.map((email, i) => (
          <Tag
            className="email-tag"
            key={i}
            closable={true}
            onClose={(e) => handleRemoveEmail(e, i)}
          >
            {email}
          </Tag>
        ))}
      </div>
    </Form>
  );

  /* methods */
  async function onAddEmail() {
    try {
      await form.validateFields();
      setEmailList((list) => {
        let newList = [...list, email];
        onChange?.(newList);
        return newList;
      });
      return form.resetFields();
    } catch (error) {
      console.error(error);
    }
  }

  async function handleInputKeyDown(e) {
    const { key } = e;
    if (key === "Enter" || key === " " || key === "," || key === ";") {
      e.preventDefault();
      onAddEmail();
    }
  }
  async function onValidateEmail(_, value) {
    if (!isEmailFormat(value)) {
      return Promise.reject(new Error("Not a valid email format"));
    }

    let foundIndex = emailList.filter(
      (x) => x.toLowerCase() === value.toLowerCase()
    );
    if (foundIndex.length > 0) {
      return Promise.reject(new Error("This email is already listed"));
    }

    return Promise.resolve();
  }

  async function handleRemoveEmail(e, index) {
    let newList = [...emailList];
    newList.splice(index, 1);

    setEmailList([...newList]);
  }
}
