import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { Input, Tooltip, Icon, Toast, Select, Button } from "@alpaca/framework";
import "./environmentModal.styles.scss";
import {
  getTenantInfo,
  getTenantIdByDomainName,
  getBCCompanyList,
  saveBCEnvironment,
} from "../../../../service/environment/environment";

const SetupBCEnvironment = forwardRef(({ changeSaveStatus }, ref) => {
  const user = useSelector((state) => state.userInfo);
  const [domainName, setDomainName] = useState("");
  const [tenantInfo, setTenantInfo] = useState(null);
  const [BCCompanyList, setBCCompanyList] = useState([]);
  const [disabled, setDisable] = useState(false);
  const toastRef = useRef(null);
  useEffect(() => {
    getTenant();
  }, [user]);

  const getTenant = async () => {
    getTenantInfo(user?.company_name)
      .then((result) => {
        setTenantInfo(result || null);
        setDisable(!!result?.bc_company);
      })
      .catch((error) => {
        toastRef.current.open({
          title: "Can not get Business Central Credential",
          description: error.message,
          duration: 3,
          status: "error",
        });
      });
  };

  const getTenantId = async () => {
    getTenantIdByDomainName(domainName)
      .then((result) => {
        if (result.success) {
          setTenantInfo({
            ...tenantInfo,
            tenant_id: result.data,
          });
        } else {
          toastRef.current.open({
            title: "Can not get tenant id",
            description: result.data,
            duration: 3,
            status: "error",
          });
        }
      })
      .catch((error) => {
        toastRef.current.open({
          title: "Can not get tenant id",
          description: error.message,
          duration: 3,
          status: "error",
        });
      });
  };
  const getBCCompanyListData = () => {
    getBCCompanyList({ ...tenantInfo, company_name: user?.company_name })
      .then((result) => {
        setBCCompanyList(result);
      })
      .catch((error) => {
        setBCCompanyList([]);
        toastRef.current.open({
          title: "Wrong tenant info!",
          description: error.message,
          duration: 3,
          status: "error",
        });
      });
  };
  useImperativeHandle(ref, () => ({
    async handleSave() {
      try {
        let result = await saveBCEnvironment({
          ...tenantInfo,
          company_name: user?.company_name,
        });
        setDomainName("");
        setTenantInfo(null);
        return result;
      } catch (error) {
        toastRef.current.open({
          title: "Save fail",
          description: error.message,
          duration: 3,
          status: "error",
        });
      }
    },
  }));

  return (
    <>
      <Toast ref={toastRef} />
      <div className="create-env-container">
        <div className="create-env-input-btn-type">
          <h3>Domain Name:</h3>
          <Input
            disabled={!!tenantInfo?.bc_company}
            className="create-env-input-main"
            dropdownStyle={{ height: "auto" }}
            style={{
              width: "100%",
            }}
            value={domainName}
            onChange={(e) => setDomainName(e.target.value)}
            placeholder="Company"
            size="large"
          ></Input>
          <Button
            className="btn secondary"
            onClick={getTenantId}
            disabled={!!tenantInfo?.bc_company}
          >
            Get Tenant ID
          </Button>
        </div>
        <div className="create-env-input-btn">
          <p className="subtext">Tenant ID:</p>
          <p className="subtext">
            {tenantInfo?.tenant_id || "####-####-####-####"}
          </p>
        </div>
        <div className="create-env-input-btn-special">
          <h3>Client ID:</h3>
          <Input
            disabled={!!tenantInfo?.bc_company}
            className="create-env-input-main"
            placeholder="ID"
            value={tenantInfo?.client_id || ""}
            onChange={(e) =>
              setTenantInfo({
                ...tenantInfo,
                client_id: e.target.value,
              })
            }
            style={{
              width: "100%",
            }}
            size="large"
          ></Input>
          <Tooltip
            title={
              <p className="white-tooltip">
                Link to <span className="link-text">Tutorial</span>
              </p>
            }
            placement="top"
            zIndex={99999}
          >
            <Icon icon="question" className="help-circle" />
          </Tooltip>
        </div>
        <div className="create-env-input-btn-type">
          <h3>Client Secret:</h3>
          <Input
            disabled={!!tenantInfo?.bc_company}
            className="create-env-input-main"
            placeholder="Secret"
            style={{
              width: "100%",
            }}
            value={tenantInfo?.client_secret || ""}
            onChange={(e) =>
              setTenantInfo({
                ...tenantInfo,
                client_secret: e.target.value,
              })
            }
            size="large"
          ></Input>
          <Button
            className="btn secondary"
            onClick={getBCCompanyListData}
            disabled={!!tenantInfo?.bc_company}
          >
            Check Tenant Info
          </Button>
        </div>
        {BCCompanyList.length > 0 || !!tenantInfo?.bc_company ? (
          <div className="create-env-container">
            <div className="create-env-input-btn">
              <h3>BC Company:</h3>
              <Select
                disabled={disabled}
                className="create-env-input-main"
                dropdownStyle={{ height: "auto" }}
                style={{
                  width: "100%",
                }}
                value={tenantInfo?.bc_company}
                onChange={(value) => {
                  setTenantInfo({
                    ...tenantInfo,
                    bc_company: value,
                  });
                  changeSaveStatus(true);
                }}
                placeholder="Business Central Company"
                size="large"
              >
                {BCCompanyList.map((company) => (
                  <Select.Option key={company.Name} value={company.Name}>
                    {company.Name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        ) : null}
        {/*  <div className="create-env-input-btn-type">
        <h3>Type:</h3>
        <Input
          className="create-env-input-main"
          placeholder="Type"
          style={{
            width: "100%",
          }}
          size="large"
        ></Input>
        <h4>Eg. PROD, TEST, DEV...</h4>
      </div>
      <div className="create-env-input-btn">
        <h3>Display Name:</h3>
        <Input
          className="create-env-input-main"
          placeholder="Display Name"
          style={{
            width: "100%",
          }}
          size="large"
        ></Input>
        </div>*/}
      </div>
    </>
  );
});

export default SetupBCEnvironment;
