import axios from "axios";
import config from "../../config";
const ROUTER_NAME = "visualSetting";
const { handleAxiosError } = require("../error.handler.service");
export const updateVisualSettingService = async (visualSetting) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}`,
      visualSetting
    );
    return resp.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const getVisualSettingService = async (company) => {
  try {
    const resp = await axios.get(`${config.IP_ADDRESS}/${ROUTER_NAME}`, {
      params: { company: company },
    });
    return resp.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const getWideLogoService = async (company) => {
  try {
    const resp = await axios.get(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/authorization/wideLogo`,
      {
        params: { company: company },
      }
    );
    return resp.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};
