import { useState, useMemo } from "react";
import { FocusInputs } from "@alpaca/framework";
import "./name-input.scss";

function NameInput({ value, onChange, ...restProps }) {
  // Internal values
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  // True values (component will prioritize value props)
  const trueFirstName = useMemo(() => {
    return value?.firstName ? value.firstName : firstName;
  }, [value, firstName]);

  const trueLastName = useMemo(() => {
    return value?.lastName ? value.lastName : lastName;
  }, [value, lastName]);

  return (
    <div className="name-input">
      <FocusInputs.Input
        {...restProps}
        id="FirstName"
        title="First Name"
        value={trueFirstName}
        onChange={changeFirstName}
      />
      <FocusInputs.Input
        {...restProps}
        id="LastName"
        title="Last Name"
        value={trueLastName}
        onChange={changeLastName}
      />
    </div>
  );

  // Methods
  function changeFirstName(e) {
    const value = e.target.value;
    setFirstName(value);
    onChange?.({ firstName: value, lastName });
  }

  function changeLastName(e) {
    const value = e.target.value;
    setLastName(value);
    onChange?.({ firstName, lastName: value });
  }
}

export default NameInput;
