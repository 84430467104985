import axios from "axios";
import config from "../../config";
import { handleAxiosError } from "../error.handler.service";
async function attemptLogin(obj) {
  //todo this means nothing. need additional logic and documentation like what is obj???
  return await axios
    .post(`${config.IP_ADDRESS}/profile/authorization/login`, {
      ...obj,
      // company_name: window.location.pathname.split("/")[1] || "steelhaus",
    })
    .then((result) => result.data)
    .catch((error) => handleAxiosError(error));
  // console.log(result);
  // const formatResult = {
  //   succeeded: result.data.data,
  // };
  // if (formatResult.succeeded) {
  //   formatResult.info = result.data.info;
  // } else {
  //   formatResult.info = result.data.err;
  // }
  // return formatResult;
}

export default attemptLogin;
