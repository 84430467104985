import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import { visualReducer } from "./reducer/visualReducer";
import { userInfoReducer } from "./reducer/userInfoReducer";
import config from "../config";

const persistConfig = {
  key: config.BASENAME || "root",
  storage,
};

const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  visualSetting: visualReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
