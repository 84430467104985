import React, { Component } from "react";
import "./settings.styles.css";
import Settings from "./settings.component";
import LogoIcon from "../../../assets/AlpacaLogo/LogoIcon.png";
import LogoHorizontal from "../../../assets/AlpacaLogo/HorizontalLogo.png";
import {
  Result,
  Tooltip,
  Upload,
  Button,
  Toast,
  Icon,
} from "@alpaca/framework";
import { connect } from "react-redux";
import { setVisualSetting } from "../../../store/reducer/visualReducer";
import {
  updateVisualSettingService,
  getVisualSettingService,
} from "../../../service/VisualChanges/visualChanges";
import { ClassSelector } from "../../../store/reduxSelector";
import { checkPermission } from "../../../service/permission/permission";

const mapDispatchToProps = (dispatch) => {
  return {
    updateVisualRedux: (setting) => dispatch(setVisualSetting(setting)),
  };
};
class CustomizationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo_small: props.visualSetting?.logo_small || LogoIcon,
      logo_wide: props.visualSetting?.logo_wide || LogoHorizontal,
      logo_label: props.visualSetting?.logo_label || LogoHorizontal,
      colors: {
        primary: props.visualSetting?.color_main?.split(",")[0] || "#000000",
        secondary:
          props.visualSetting?.color_secondary?.split(",")[0] || "#A51414",
        tertiary: props.visualSetting?.color_tertiary || "#24c6dc",
        text: props.visualSetting?.color_main?.split(",")[1] || "#000000",
        alttext:
          props.visualSetting?.color_secondary?.split(",")[1] || "#FFFFFF",
      },
      collapse: {
        client: false,
        1: false,
        2: false,
        3: false,
        4: false,
      },
    };
    this.toastRef = React.createRef();
  }

  saveChanges() {
    if (checkPermission("Apply Customization Settings")) {
      this.updateVisualSettingApi(
        this.state.logo_small,
        this.state.logo_wide,
        this.state.logo_label,
        this.state.colors
      );
    } else {
      this.toastRef.current.open({
        title: "Permission Denied",
        description: "You do not have permission to update this!",
        duration: 6,
        status: "error",
      });
    }
  }

  updateVisualSettingApi(logo_small, logo_wide, logo_label, colors) {
    // if (result.status) {
    const newVisualSetting = {
      logo_small: logo_small,
      logo_wide: logo_wide,
      logo_label: logo_label,
      color_main: (colors.primary || "") + "," + (colors.text || ""),
      color_secondary: (colors.secondary || "") + "," + (colors.alttext || ""),
      color_tertiary: colors.tertiary,
    };
    updateVisualSettingService({
      company: this.props.userInfo?.company,
      ...newVisualSetting,
    })
      .then((result) => {
        this.props.updateVisualRedux(newVisualSetting);
        this.toastRef.current.open({
          title: "Save Success",
          description: "New setting saved",
          duration: 3,
          status: "success",
        });
      })
      .catch((error) => {
        this.toastRef.current.open({
          title: "Save Fail",
          description: error.message,
          duration: 3,
          status: "error",
        });
      });
  }

  updateVisualSettingRedux(logo_small, logo_wide, colors) {
    const newVisualSetting = {
      logo_small: logo_small,
      logo_wide: logo_wide,
      color_main: (colors.primary || "") + "," + (colors.text || ""),
      color_secondary: (colors.secondary || "") + "," + (colors.alttext || ""),
      color_tertiary: colors.tertiary,
    };
    this.props.updateVisualRedux(newVisualSetting);
  }

  resetVisualSetting() {
    getVisualSettingService(this.props.userInfo?.company)
      .then((res) => {
        if (res) {
          const logo_small = res.logo_small || LogoIcon;
          const logo_wide = res.logo_wide || LogoHorizontal;
          const colors = {
            primary: res.color_main?.split(",")[0] || "#000000",
            secondary: res.color_secondary?.split(",")[0] || "#A51414",
            tertiary: res.color_tertiary || "#24c6dc",
            text: res.color_main?.split(",")[1] || "#000000",
            alttext: res.color_secondary?.split(",")[1] || "#FFFFFF",
          };
          this.updateVisualSettingRedux(logo_small, logo_wide, colors);
          this.setState({
            logo_small: logo_small,
            logo_wide: logo_wide,
            colors: colors,
          });
        }
      })
      .catch((error) => {
        this.toastRef.current.open({
          title: "Get Visual Setting fail!",
          description: error.message,
          duration: 3,
          status: "error",
        });
      });
  }

  changeColor(type, color) {
    let colors = JSON.parse(JSON.stringify(this.state.colors));
    colors[type] = color;
    this.setState({
      colors: colors,
    });
  }

  generateButtonText(name) {
    return (
      <button
        className="toggle-settings-btn"
        onClick={() => {
          let oldCollapse = this.state.collapse;
          oldCollapse[name] = !oldCollapse[name];
          this.setState({
            collapse: oldCollapse,
          });
        }}
      >
        {this.state.collapse[name] ? (
          <i class="fa-solid fa-caret-down" id="caret"></i>
        ) : (
          <i class="fa-sharp fa-solid fa-caret-right" id="caret"></i>
        )}
      </button>
    );
  }

  onUpload = (info, type) => {

    this.convertBase64(info.file, (url) => {
      if (type === "small") {
        this.setState({
          logo_small: url,
        });
      } else if (type === "wide") {
        this.setState({
          logo_wide: url,
        });
      } else if (type === "label") {
        this.setState({
          logo_label: url,
        });
      }
    });
  };

  convertBase64 = (source, callback) => {
    let fileName = source.name;
    let extension = fileName.split(".").pop().toLowerCase();
    if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
      throw new Error("Not acceptable format");
    }
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(source);
  };

  render() {
    return (
      <div className="full-height">
        <Toast ref={this.toastRef} />
        <Settings />
        <div className="setting-grid">
          <div className="settings-container">
            {/* Start Client Config */}
            <div className="head-btn-container">
              <div className="head-btn-subheader">
                <h1>Client Configuration</h1>
                <p>Customize your app experience.</p>
              </div>
            </div>
            {/* {this.state.collapse["client"] && ( */}
            <div className="client-config-container">
              <div className="client-logo-container">
                <div className="client-config-item">
                  <h3>Main Logo</h3>
                  <div className="logo-horizontal-canvas-black">
                    <img
                      src={this.state.logo_wide}
                      alt="Logo"
                      onError={(e) => {
                        this.toastRef.current.open({
                          title: "Fail to load Horizontal Logo",
                          description: "Please try again or upload a new image",
                          duration: 5,
                          status: "error",
                        });
                        e.target.src = LogoHorizontal;
                      }}
                    />
                  </div>
                  <caption>
                    Recommended: 200px x 100px PNG with transparent background
                  </caption>
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    accept=".png"
                    onChange={(file) => this.onUpload(file, "wide")}
                    maxCount={1}
                    fileList={[]}
                    beforeUpload={(file) => {
                      return false;
                    }}
                  >
                    <button className="main btn upload-btn">
                      <Icon icon="pencil" /> &nbsp; Change Logo
                    </button>
                  </Upload>
                </div>

                <div className="client-config-item">
                  <h3>Logo Icon</h3>
                  <div className="logo-icon-canvas">
                    <img
                      src={this.state.logo_small}
                      alt="Logo"
                      style={{ width: "50px", height: "50px" }}
                      onError={(e) => {
                        this.toastRef.current.open({
                          title: "Fail to load Logo Icon",
                          description: "Please try again or upload a new image",
                          duration: 5,
                          status: "error",
                        });
                        e.target.src = LogoIcon;
                      }}
                    />
                  </div>
                  <caption>
                    Recommended: 50px x 50px PNG with transparent background
                  </caption>
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    accept=".png"
                    onChange={(file) => this.onUpload(file, "small")}
                    maxCount={1}
                    fileList={[]}
                    beforeUpload={(file) => {
                      return false;
                    }}
                  >
                    <button className="main btn upload-btn">
                      <Icon icon="pencil" className="alttext" /> &nbsp; Change
                      Logo
                    </button>
                  </Upload>
                </div>

                <div className="client-config-item">
                  <h3>Label Logo</h3>
                  <Tooltip title="If your label isn't visible on a white background, it will have issues printed on a label as well.">
                    <div className="logo-horizontal-canvas-white">
                      <img
                        src={this.state.logo_label}
                        alt="Logo"
                        onError={(e) => {
                          this.toastRef.current.open({
                            title: "Fail to load Horizontal Logo",
                            description:
                              "Please try again or upload a new image",
                            duration: 5,
                            status: "error",
                          });
                          e.target.src = LogoHorizontal;
                        }}
                      />
                    </div>
                  </Tooltip>
                  <caption>
                    Recommended: 150px x 50px PNG with transparent background
                  </caption>
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    accept=".png"
                    onChange={(file) => this.onUpload(file, "label")}
                    maxCount={1}
                    fileList={[]}
                    beforeUpload={(file) => {
                      return false;
                    }}
                  >
                    <button className="main btn upload-btn">
                      <Icon icon="pencil" /> &nbsp; Change Logo
                    </button>
                  </Upload>
                </div>
              </div>

              <div className="client-config-item-color">
                <h3>
                  Colour Palette
                  <Tooltip
                    title="Resets colours to company defaults set by an Admin."
                    placement="top"
                    zIndex={99999}
                  >
                    <button
                      className="reset-btn"
                      onClick={() => this.resetVisualSetting()}
                    >
                      Reset to Defaults
                    </button>
                  </Tooltip>
                </h3>
                <div className="color-picker-section">
                  <div className="color-picker-item">
                    <div className="color-picker-grid">
                      <Tooltip
                        title="Changes sidebar colour and other base UI elements."
                        placement="top"
                        zIndex={99999}
                      >
                        <div className="color-picker-container">
                          <input
                            name="color"
                            type="color"
                            // value="#fdd20e"
                            value={this.state.colors.primary}
                            onChange={(input) => {
                              this.changeColor("primary", input.target.value);
                            }}
                          ></input>
                        </div>
                      </Tooltip>
                    </div>
                    <p>Primary Colour</p>
                  </div>

                  <div className="color-picker-item">
                    <div className="color-picker-grid">
                      <Tooltip
                        title="Changes all UI elements that use an accent colour, like buttons."
                        placement="top"
                        zIndex={99999}
                      >
                        <div className="color-picker-container">
                          <input
                            name="color"
                            type="color"
                            // value="#fdd20e"
                            value={this.state.colors.secondary}
                            onChange={(input) => {
                              this.changeColor("secondary", input.target.value);
                            }}
                          ></input>
                        </div>
                      </Tooltip>
                    </div>
                    <p>Secondary Colour</p>
                  </div>

                  <div className="color-picker-item">
                    <div className="color-picker-grid">
                      <Tooltip
                        title="Changes elements that require another color instead of secondary."
                        placement="top"
                        zIndex={99999}
                      >
                        <div className="color-picker-container">
                          <input
                            name="color"
                            type="color"
                            // value="#fdd20e"
                            value={this.state.colors.tertiary}
                            onChange={(input) => {
                              this.changeColor("tertiary", input.target.value);
                            }}
                          ></input>
                        </div>
                      </Tooltip>
                    </div>
                    <p>Tertiary Colour</p>
                  </div>

                  <div className="color-picker-item">
                    <div className="color-picker-grid">
                      <Tooltip
                        title="Changes default text colour."
                        placement="top"
                        zIndex={99999}
                      >
                        <div className="color-picker-container">
                          <input
                            name="color"
                            type="color"
                            // value="#fdd20e"
                            value={this.state.colors.text}
                            onChange={(input) => {
                              this.changeColor("text", input.target.value);
                            }}
                          ></input>
                        </div>
                      </Tooltip>
                    </div>
                    <p>Primary Text Colour</p>
                  </div>

                  <div className="color-picker-item">
                    <div className="color-picker-grid">
                      <Tooltip
                        title="Changes text colour inside buttons that use the secondary colour."
                        placement="top"
                        zIndex={99999}
                      >
                        <div className="color-picker-container">
                          <input
                            name="color"
                            type="color"
                            // value="#fdd20e"
                            value={this.state.colors.alttext}
                            onChange={(input) => {
                              this.changeColor("alttext", input.target.value);
                            }}
                          ></input>
                        </div>
                      </Tooltip>
                    </div>
                    <p>Secondary Text Colour</p>
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}
            <div style={{ display: "grid", gridTemplateColumns: "auto auto" }}>
              <div className="profile-btn-container">
                <Tooltip
                  title="Settings apply to all users in company."
                  placement="top"
                  zIndex={99999}
                >
                  <button
                    onClick={() => this.saveChanges()}
                    className="main btn"
                  >
                    Save Changes
                  </button>
                </Tooltip>
                <button
                  onClick={() =>
                    this.updateVisualSettingRedux(
                      this.state.logo_small,
                      this.state.logo_wide,
                      this.state.colors
                    )
                  }
                  className="secondary btn"
                >
                  Preview
                </button>
              </div>
            </div>
          </div>
          <div className="preview-container-outer">
            <h2>Examples</h2>
            <div className="color-preview-container">
              <button className="preview-btn preview-main">Button</button>
              <button className="preview-btn preview-hover">
                Button, Active
              </button>
              <button className="preview-btn preview-light">
                Button, Light
              </button>
            </div>
            <div className="pb__modal__header-example">
              <h1 className="alttext">Modal Header</h1>
              <div className="pb__modal__controls-example">
                <div className={"pb__modal__close-example"}>
                  <Icon icon={"close"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const stateList = ["userInfo", "visualSetting"];
export default connect(
  (state) => ClassSelector(state, stateList),
  mapDispatchToProps
)(CustomizationSettings);
