import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./userSettingsTabs.styles.css";

function UserSettingsTabs(props) {
  const toggles = [
    "Customization",
    "Profile",
    "Environments",
    "Users",
    "Notification",
  ];
  const [toggleState, setToggleState] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const subPathname = location.pathname.split("Settings/")[1];
    const toggleIndex = toggles.findIndex((ele) =>
      subPathname?.startsWith(ele)
    );
    setToggleState(toggleIndex === -1 ? 0 : toggleIndex);
  }, [location]);

  const toggleTab = (index) => {
    navigate("/dashboard/Settings/" + toggles[index]);
  };

  return (
    <>
      {/* Tab Buttons */}
      <div className="settings-selector-container">
        <button
          className={
            toggleState === 0 ? "setting-selector-active" : "setting-selector"
          }
          onClick={() => toggleTab(0)}
        >
          Customization
        </button>
        <button
          className={
            toggleState === 1 ? "setting-selector-active" : "setting-selector"
          }
          onClick={() => toggleTab(1)}
        >
          Profile
        </button>
        <button
          className={
            toggleState === 2 ? "setting-selector-active" : "setting-selector"
          }
          onClick={() => toggleTab(2)}
        >
          Environments
        </button>
        <button
          className={
            toggleState === 3 ? "setting-selector-active" : "setting-selector"
          }
          onClick={() => toggleTab(3)}
        >
          Users
        </button>
        <button
          className={
            toggleState === 4 ? "setting-selector-active" : "setting-selector"
          }
          onClick={() => toggleTab(4)}
        >
          Notification
        </button>
      </div>
    </>
  );
}

export default UserSettingsTabs;
