export function isolateUnique(array, property) {
  return [...new Set(array.map((item) => item[property]))];
}

export function stripArray(input) {
  return Array.isArray(input) && input.length === 1 ? input[0] : input;
}

export function isObjectEmpty(objectName) {
  for (let prop in objectName) {
    if (objectName.hasOwnProperty(prop)) {
      return false;
    }
  }
  return true;
}
