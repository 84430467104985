import "./settings.styles.css";
import React, { Component } from "react";
import { Icon, Table, Modal, TableHeader, Toast } from "@alpaca/framework";
import Settings from "./settings.component";
import CreateEnvironment from "./environmentModal/createEnvironment.component";
import SetupBCEnvironment from "./environmentModal/setupBCEnvironment.component";
import SetupSPEnvironment from "./environmentModal/setupSPEnvironment.component";
import NoData from "./../../../assets/Img/nodata_NoData_NoData.svg";
import { connect } from "react-redux";
import {
  // getBCCompanyList,
  getAllEnvironment,
  saveBCEnvironment,
  getBCResource,
} from "../../../service/environment/environment";
import { isolateUnique } from "../../../utils/array";
import Highlighter from "../../../components/highlighter/highlighter.component";
import { sieveObjectByKeys } from "../../../utils/sieveObject";
import { checkPermission } from "../../../service/permission/permission";

class EnvironmentSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandStatus: true,
      environmentList: [],
      setupOpen: false,
      sharePointOpen: false,
      createEnv: false,
      BCResource: null,
      bcCompanyList: [],
      saveStatus: false,
      searchEnvironment: "",
    };
    this.setUpRef = React.createRef();
    this.createRef = React.createRef();
    this.toastRef = React.createRef();
  }

  componentDidMount() {
    //  this.getBCCompanyData();
    // this.getEnvironmentData();
    this.getBCResourceData();
  }
  async getBCResourceData() {
    getBCResource(this.props.userInfo.company_name).then((result) => {
      this.setState({ BCResource: result });
    });
  }
  // async getBCCompanyData() {
  //   getBCCompanyList(this.props.userInfo.company_name).then((result) => {
  //     console.log(result);
  //     this.setState({ bcCompanyList: result });
  //   });
  // }

  async getEnvironmentData() {
    getAllEnvironment(this.props.userInfo.company_name)
      .then((result) => {
        this.setState({ environmentList: result });
      })
      .catch((error) => {
        this.toastRef.current?.open({
          title: "Get Environment Data Fail",
          description: error.message,
          duration: 6,
          status: "error",
        });
      });
  }

  async saveBCCreate() {
    if (this.createRef.current) {
      this.createRef.current.handleSave().then((result) => {
        // console.log(result);
        // if (result) {
        //   saveBCEnvironment({
        //     ...result,
        //     company_name: this.props.userInfo.company_name,
        //   }).then((result) => {
        if (result) {
          this.getEnvironmentData();
          this.setState({ createEnv: false });
        }
        // });
        // }
      });
    }
  }

  onClick() {
    // if (this.state.bcCompanyList.length === 0) {
    if (checkPermission("Setup Environment")) {
      this.setState({ setupOpen: true });
    } else {
      this.toastRef.current.open({
        title: "Permission Denied",
        description:
          "You do not have permission to setup Business Central Connection",
        duration: 6,
        status: "error",
      });
    }
    // } else {
    //   if (checkPermission("Create Environment")) {
    //     this.setState({ createEnv: true });
    //   } else {
    //     this.toastRef.current.open({
    //       title: "Permission Denied",
    //       description: "You do not have permission to create environment",
    //       duration: 6,
    //       status: "error",
    //     });
    //   }
    // }
  }
  openSharePoint = () => {
    if (checkPermission("Setup Environment")) {
      this.setState({ sharePointOpen: true });
    } else {
      this.toastRef.current.open({
        title: "Permission Denied",
        description:
          "You do not have permission to setup SharePoint Connection",
        duration: 6,
        status: "error",
      });
    }
  };
  changeSaveStatus = (status) => {
    this.setState({ saveStatus: status });
  };
  render() {
    let columns = [
      {
        title: "Name",
        dataIndex: "environment_name",
        key: "name",
        sorter: (a, b) => a.environment_name?.localeCompare(b.name),
        sortDirections: ["descend", "ascend"],
        render: (name) => (
          <Highlighter highlight={this.state.searchEnvironment}>
            {name}
          </Highlighter>
        ),
      },
      {
        title: "Type",
        dataIndex: "environment_type",
        key: "type",
        sorter: (a, b) => a.environment_type?.localeCompare(b.type),
        sortDirections: ["descend", "ascend"],
        render: (name) => (
          <Highlighter highlight={this.state.searchEnvironment}>
            {name}
          </Highlighter>
        ),
      },
      {
        title: "BC Name",
        dataIndex: "bc_company",
        key: "bc_name",
        sorter: (a, b) => a.bc_company?.localeCompare(b.bc_name),
        sortDirections: ["descend", "ascend"],
        render: (name) => (
          <Highlighter highlight={this.state.searchEnvironment}>
            {name}
          </Highlighter>
        ),
      },
      {
        /*
        title: "Actions",
        render: (_, record) => <EnvironmentButton />,
    */
      },
    ];

    let visibleEnvironments = sieveObjectByKeys(
      this.state.environmentList,
      this.state.searchEnvironment,
      isolateUnique(columns, "dataIndex")
    );

    return (
      <>
        <Toast ref={this.toastRef} />

        <div className="full-height">
          <Settings />
          <div className="setting-grid">
            <div className="env-setting-grid">
              <div className="head-btn-container">
                <TableHeader
                  title="Environments"
                  // options={[]}
                  showOptions={false}
                  extraComponent={
                    <>
                      <button
                        className="main device-settings-btn"
                        onClick={() => {
                          this.onClick();
                        }}
                      >
                        <Icon icon="plus" className="alttext" />
                        Set Up Business
                        <br />
                        Central Connection
                      </button>
                      <button
                        className="main device-settings-btn"
                        onClick={() => {
                          this.openSharePoint();
                        }}
                      >
                        <Icon icon="plus" className="alttext" />
                        Set Up SharePoint
                        <br />
                        Connection
                      </button>
                    </>
                  }
                  onChange={(searchInput) =>
                    this.setState({ searchEnvironment: searchInput })
                  }
                />
              </div>
              <Table
                columns={columns}
                dataSource={visibleEnvironments}
                pagination={false}
                className="settings-table"
                locale={{
                  emptyText: (
                    <div className="tower-container">
                      <img
                        alt="no data is available"
                        src={NoData}
                        width="150"
                      />
                      <h1 className="mb20">There are no environments here!</h1>
                    </div>
                  ),
                }}
              />
            </div>
            <Modal
              title="Create Environment"
              width="800px"
              height="400px"
              open={this.state.createEnv}
              onCancel={() => this.setState({ createEnv: false })}
              children={
                <CreateEnvironment
                  bcCompanyList={this.state.bcCompanyList}
                  ref={this.createRef}
                />
              }
              footer={
                <div className="user-settings-row">
                  <button
                    className="btn main"
                    onClick={() => {
                      this.saveBCCreate();
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="btn secondary"
                    onClick={() => {
                      this.setState({ createEnv: false });
                    }}
                  >
                    Cancel
                  </button>
                </div>
              }
            />

            <Modal
              onCancel={() => {
                this.setState({ setupOpen: false, saveStatus: false });
              }}
              title="Setup Business Central"
              width="900px"
              height="600px"
              open={this.state.setupOpen}
              maskClosable
              children={
                <SetupBCEnvironment
                  ref={this.setUpRef}
                  changeSaveStatus={this.changeSaveStatus}
                />
              }
              footer={
                <div className="user-settings-row">
                  {this.state.saveStatus && (
                    <button
                      className="btn main"
                      onClick={() => {
                        if (this.setUpRef.current) {
                          this.setUpRef.current.handleSave().then((result) => {
                            if (result) {
                              this.setState({
                                setupOpen: false,
                                saveStatus: false,
                              });
                            }
                          });
                        }
                      }}
                    >
                      Save
                    </button>
                  )}
                  <button
                    onClick={() => {
                      this.setState({ setupOpen: false, saveStatus: false });
                    }}
                    className="btn secondary"
                  >
                    Cancel
                  </button>
                </div>
              }
            />
            <Modal
              onCancel={() => {
                this.setState({ sharePointOpen: false, saveStatus: false });
              }}
              title="Setup Sharepoint"
              width="900px"
              height="600px"
              open={this.state.sharePointOpen}
              maskClosable
              children={
                <SetupSPEnvironment
                  ref={this.setUpRef}
                  changeSaveStatus={this.changeSaveStatus}
                />
              }
              footer={
                <div className="user-settings-row">
                  {this.state.saveStatus && (
                    <button
                      className="btn main"
                      onClick={() => {
                        if (this.setUpRef.current) {
                          this.setUpRef.current.handleSave().then((result) => {
                            if (result) {
                              this.setState({
                                sharePointOpen: false,
                                saveStatus: false,
                              });
                            }
                          });
                        }
                      }}
                    >
                      Save
                    </button>
                  )}
                  <button
                    onClick={() => {
                      this.setState({
                        sharePointOpen: false,
                        saveStatus: false,
                      });
                    }}
                    className="btn secondary"
                  >
                    Cancel
                  </button>
                </div>
              }
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ userInfo: state.userInfo });
export default connect(mapStateToProps)(EnvironmentSettings);
