import RegisterImg from "../../assets/Img/RegisterComponent/register-img.png";
import HorizontalLogo from "../../assets/AlpacaLogo/HorizontalLogo.png";
import RegisterForm from "../register/components/register-form/register-form.component";
import InviteForm from "./invite-form/invite-form.component";
import { useEffect, useState, useRef } from "react";
import { ValidateInviteTokenService } from "../../service/invite/invite";
import { useLocation, useNavigate } from "react-router-dom";
import "./invited.styles.scss";
import { Toast } from "@alpaca/framework";
import { getWideLogoService } from "../../service/VisualChanges/visualChanges";
function Invited() {
  const [validToken, setValidToken] = useState(false);
  const [inviteData, setInviteData] = useState(null);
  const [wideLogo, setWideLogo] = useState(HorizontalLogo);
  const toastRef = useRef(null);
  const location = useLocation();
  const token = location.pathname.split("/invited/")[1];
  useEffect(() => {
    if (token) {
      ValidateInviteTokenService(token)
        .then((result) => {
          setValidToken(result.success);
          if (!result.success) {
            toastRef.current.open({
              title: result.message,
              description: result.description,
              duration: 5,
              status: "error",
            });
          }

          if (result.data) {
            setInviteData(result.data);
            getWideLogoService(result.data.company_name)
              .then((result) => {
                if (result) {
                  setWideLogo(result);
                }
              })
              .catch((error) => {
                toastRef.current?.open({
                  title: "Get Company Logo failed",
                  description: error.message,
                  duration: 3,
                  status: "error",
                });
              });
          }
        })
        .catch((error) => {
          setValidToken(false);
          toastRef.current.open({
            title: "Valid Invitation Link Error",
            description: error.message,
            duration: 5,
            status: "error",
          });
        });
    } else {
      toastRef.current.open({
        title: "Password reset is invalid",
        description:
          "Your link is invalid, please ask administrator to send you invitation again!",
        duration: 5,
        status: "error",
      });
    }
  }, [token]);
  const openToast = (toastData) => {
    toastRef.current.open(toastData);
  };
  return (
    <div>
      <Toast ref={toastRef} />
      <div className="invited">
        <nav className="header">
          <img
            src={wideLogo}
            alt="horizontal logo"
            onError={(e) => {
              e.target.src = HorizontalLogo;
            }}
          />
        </nav>
        <div className="content">
          <div className="card">
            <h1 className="card-title App">
              {`Welcome${inviteData ? ` to ${inviteData.company_name}` : ""}!`}
            </h1>
            {inviteData ? (
              <p className="body-text App mb20">{`Invited by ${inviteData.admin_name}`}</p>
            ) : null}
            {validToken ? (
              <div className="form">
                <InviteForm
                  token={token}
                  inviteData={inviteData}
                  openToast={openToast}
                />
              </div>
            ) : null}
            {/* <img
              src={RegisterImg}
              alt="Register Illustration"
              className="register-illustration"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invited;
