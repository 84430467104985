import React from "react";
import { useSelector } from "react-redux";

export const UserInfoSelector = () => {
  return useSelector((state) => state.userInfo);
};

export const VisualSettingSelector = () => {
  return useSelector((state) => state.visualSetting);
};

export const ClassSelector = (state, stateList) => {
  return stateList.reduce((obj, item) => {
    obj[item] = state[item];
    return obj;
  }, {});
};
