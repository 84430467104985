import { Select, Input, Toast } from "@alpaca/framework";
import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./environmentModal.styles.scss";

const CreateEnvironment = forwardRef((props, ref) => {
  const [bcCompany, setBCCompany] = useState(null);
  const [name, setName] = useState(null);
  const toastRef = useRef(null);
  useImperativeHandle(ref, () => ({
    async handleSave() {
      if (!bcCompany || !name) {
        toastRef.current.open({
          title: "Empty Input",
          description: "Please check the input",
          duration: 3,
          status: "error",
        });
        return null;
      } else {
        return {
          bc_company: bcCompany,
          environment_name: name,
        };
      }
    },
  }));
  return (
    <div>
      <Toast ref={toastRef} />
      <div className="create-env-container">
        <div className="create-env-input-btn">
          <h3>BC Company:</h3>
          <Select
            className="create-env-input-main"
            dropdownStyle={{ height: "auto" }}
            style={{
              width: "100%",
            }}
            value={bcCompany}
            onChange={(value) => setBCCompany(value)}
            placeholder="Company"
            size="large"
          >
            {props.bcCompanyList.length > 0
              ? props.bcCompanyList.map((company) => (
                  <Select.Option key={company.Name} value={company.Name}>
                    {company.Name}
                  </Select.Option>
                ))
              : null}
          </Select>
        </div>
        <div className="create-env-input-btn">
          <h3>Name:</h3>
          <Input
            className="create-env-input-main"
            placeholder="Display Name"
            style={{
              width: "100%",
            }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            size="large"
          ></Input>
        </div>
      </div>
    </div>
  );
});

export default CreateEnvironment;
