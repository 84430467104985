//TODO - prep work! Goal is to organize files better instead of user profile user update user etc. too confusing and way more confusing than user / permissions

import React from "react";
import { Switch } from "@alpaca/framework";
import "./permission.styles.scss";

function UserPermission({ permissions = [], onChange }) {
  return (
    <div className="user-settings__permission-container">
      <h3>Permissions</h3>
      <hr />
      <div className="user-settings__permission-options">
        {permissions?.length !== 0 ? (
          permissions?.map((el, index) => (
            <div
              className="user-permissions-row"
              key={["user-permission", index]}
            >
              <label>{el.permission_group_name}</label>
              <Switch
                checked={el.isGranted}
                onChange={(value) => onChange?.(index, value)}
              />
            </div>
          ))
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}

export default UserPermission;
