import { isEmailFormat } from "./register-form.util";

const usernameValidator = (serverError) => [
  {
    validator: (_, value) => {
      if (serverError?.type === "username") {
        return Promise.reject(new Error(serverError?.message));
      }

      if (!value) {
        return Promise.reject(new Error("Username is required."));
      } else if (value.length < 3) {
        return Promise.reject(new Error("Your username is too short."));
      } else if (value.length > 20) {
        return Promise.reject(new Error("Your username is too long."));
      } else if (/\s/.test(value)) {
        return Promise.reject(
          new Error("Your username cannot contain any spaces.")
        );
      } else if (!/[A-Z]/.test(value) && !/[a-z]/.test(value)) {
        return Promise.reject(
          new Error("Your username must contain at least one letter character.")
        );
      } else {
        return Promise.resolve();
      }
    },
  },
];

const passwordValidator = [
  {
    validator: (_, value) => {
      if (!value) {
        return Promise.reject(new Error("Password is required."));
      } else if (value.length < 8) {
        return Promise.reject(
          new Error("Your password is too short, must be atleast 8 characters.")
        );
      } else if (value.length > 64) {
        return Promise.reject(
          new Error("Your password has reached character limit.")
        );
      } else {
        return Promise.resolve();
      }
    },
  },
];

const confirmPasswordValidator = (password) => [
  {
    validator: (_, value) => {
      if (!value) {
        return Promise.reject(new Error("Confirm Password is required."));
      } else if (value !== password) {
        return Promise.reject(
          new Error("Confirm password doesn't match New password")
        );
      } else {
        return Promise.resolve();
      }
    },
  },
];

const emailValidator = (isEmailValid, serverError) => {
  return [
    {
      validator: (_, value) => {
        if (serverError?.type === "email") {
          return Promise.reject(new Error(serverError?.message));
        }

        if (value && !isEmailFormat(value)) {
          return Promise.reject(new Error("Your email is not valid."));
        } else if (isEmailValid === false) {
          return Promise.reject(new Error("Your email is already in use."));
        } else {
          return Promise.resolve();
        }
      },
    },
  ];
};

const companyValidator = [
  {
    validator: (_, value) => {
      if (!value) {
        return Promise.reject(new Error("Company Name is required."));
      } else {
        return Promise.resolve();
      }
    },
  },
];

const companyTypeValidator = [
  {
    validator: (_, value) => {
      if (!value) {
        return Promise.reject(new Error("Company Type is required."));
      } else {
        return Promise.resolve();
      }
    },
  },
];

const nameValidator = [
  {
    validator: (_, value) => {
      if (typeof value !== "object") {
        return Promise.reject(new Error("Your Name is required."));
      } else {
        const hasFirstName =
          Object.hasOwn(value, "firstName") && !!value.firstName;
        const hasLastName =
          Object.hasOwn(value, "lastName") && !!value.lastName;

        if (!hasFirstName) {
          return Promise.reject(new Error("Please enter your first name."));
        } else if (!hasLastName) {
          return Promise.reject(new Error("Please enter your last name."));
        } else if (
          !/^[A-Za-z]*$/.test(value.firstName) ||
          !/^[A-Za-z]*$/.test(value.lastName)
        ) {
          return Promise.reject(
            new Error("Your name must be in letters only.")
          );
        } else {
          return Promise.resolve();
        }
      }
    },
  },
];

const validators = {
  username: usernameValidator,
  password: passwordValidator,
  email: emailValidator,
  company: companyValidator,
  companyType: companyTypeValidator,
  name: nameValidator,
  confirmPassword: confirmPasswordValidator,
};

export default validators;
