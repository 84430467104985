import React, { useEffect } from "react";
import { Form, FocusInputs } from "@alpaca/framework";
import "./createUserForm.scss";

const focusInputProps = {
  autoComplete: "one-time-code",
  required: true,
  requiredMarkPosition: "right",
};

/* Why autocomplete = one-time-code? 
its to stop Chrome's autofill because 
Chrome decided to be problematic and not use a really niche auto complete 
*/

// triggerReset => any variable that triggers a form reset when changed
function CreateUserForm({ form, triggerReset, onChange, onCreateUser }) {
  const password = Form.useWatch("password", form);

  useEffect(() => {
    if (triggerReset === undefined) {
      return;
    }
    form.resetFields();
  }, [triggerReset, form]);

  return (
    <>
      <div className="create-user-form form mb20">
        <Form
          form={form}
          onChange={() => onChange?.(form.getFieldsValue())}
          onFinish={(inputs) => onCreateUser?.(inputs)}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Username is required" }]}
          >
            <FocusInputs.Input {...focusInputProps} title="User Name" />
          </Form.Item>
          <Form.Item
            name="firstName"
            rules={[{ required: true, message: "First name is required" }]}
          >
            <FocusInputs.Input {...focusInputProps} title="First Name" />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: "Last name is required" }]}
          >
            <FocusInputs.Input {...focusInputProps} title="Last Name" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Password is required" }]}
          >
            <FocusInputs.Password
              {...focusInputProps}
              title="Password"
              autocomplete="one-time-code"
              visibilityToggle={false}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            rules={[{ validator: onConfirmPassword }]}
          >
            <FocusInputs.Password
              {...focusInputProps}
              title="Confirm Password"
              visibilityToggle={false}
            />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Email is required" },
              { type: "email", message: "Email is not valid" },
            ]}
          >
            <FocusInputs.Input {...focusInputProps} title="Email" />
          </Form.Item>
        </Form>
      </div>
    </>
  );

  function onConfirmPassword(_, confirmPass) {
    return !password
      ? Promise.reject(new Error("Please enter a Password above first"))
      : !confirmPass
      ? Promise.reject(new Error("Please enter the same password again"))
      : password !== confirmPass
      ? Promise.reject(new Error("Does not match password"))
      : Promise.resolve();
  }
}

export default CreateUserForm;
