import { useMemo } from "react";

export default function Highlighter({ children, highlight, highlightStyling }) {
  const segments = useMemo(() => {
    if (highlight.length === 0 || typeof children !== "string") {
      return [children];
    }
    return children.split(new RegExp(`(${highlight})`, `gi`));
  }, [children, highlight]);

  return segments.map((part, i) => (
    <span
      key={i}
      style={
        part?.toLowerCase() === highlight?.toLowerCase() ? highlightStyling : {}
      }
    >
      {part}
    </span>
  ));
}

Highlighter.defaultProps = {
  highlight: "",
  highlightStyling: {
    color: "darkorange",
  },
};
