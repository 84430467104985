import React from "react";
// import Modal from "../modal/modal.component";
import { Icon, ThreeDotMenu } from "@alpaca/framework";
export default function EditInvitationButton({
  showCancelModal,
  showResendModal,
  showForceCreateModal,
}) {
  return (
    <>
      <div className="user-settings-row">
        {/* <button className="main btn">Edit</button>
      <button className="secondary btn">Delete</button> */}
        <ThreeDotMenu
          items={[
            {
              label: "Cancel",
              icon: <Icon icon="trash" className="icon-btn" />,
              onSelect: (item) => showCancelModal(),
            },
            {
              label: "Resend",
              icon: <Icon icon="loop" className="icon-btn" />,
              onSelect: (item) => showResendModal(),
            },
            {
              label: "Force Create",
              icon: <Icon icon="check" className="icon-btn" />,
              onSelect: (item) => showForceCreateModal(),
            },
          ]}
        />
      </div>
    </>
  );
}
