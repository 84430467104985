import React from "react";
import { Icon, ThreeDotMenu } from "@alpaca/framework";

export default function EditButton({ showEditModal, showDeleteModal }) {
  return (
    <>
      <div className="user-settings-row">
        <ThreeDotMenu
          items={[
            {
              label: "Edit",
              icon: <Icon icon="gear" className="icon-btn" />,
              onSelect: () => showEditModal(),
            },
            {
              label: "Delete",
              icon: <Icon icon="trash" className="icon-btn" />,
              onSelect: () => showDeleteModal(),
            },
          ]}
        />
      </div>
    </>
  );
}
