import axios from "axios";
import config from "../../config";
import { handleAxiosError } from "../error.handler.service";
export const getAllCompanies = async () => {
  try {
    const resp = await axios.get(`${config.IP_ADDRESS}/company`);
    return await resp.data;
    // const customers = await resp.data;
    // return await customers.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const getCompanyLogos = async (companyList) => {
  try {
    const resp = await axios.get(
      `${config.IP_ADDRESS}/company/authorization/getLogo`,
      {
        params: { companyList: companyList },
      }
    );
    return resp.data;
    // const customers = await resp.data;
    // return await customers.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};
