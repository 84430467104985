import React, { useState, useRef, useEffect } from "react";
import UserAvatarComponent from "../avatar/userAvatar.component";
import "./userUpdate.styles.scss";
import { UserInfoSelector } from "../../store/reduxSelector";
import { updateProfile } from "../../service/profile/profile";
import { Switch, Toast, FocusInputs, Select } from "@alpaca/framework";
import { fetchPermissionsForUser } from "../../service/permission/permission";
import _ from "lodash";
import Permissions from "../user/permission/permission.component";
export function UserUpdate(props) {
  const user = UserInfoSelector();
  const [firstName, setFirstName] = useState(props.rowData?.first_name);
  const [lastName, setLastName] = useState(props.rowData?.last_name);
  const [status, setStatus] = useState(props.rowData?.profile_status);
  const [email, setEmail] = useState(props.rowData?.email_address);
  const [originalPermissions, setOriginalPermissions] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [permissionLoading, setPermissionLoading] = useState(false);
  const fullName = `${props.rowData?.first_name || ""} ${
    props.rowData?.last_name || ""
  }`;

  useEffect(() => {
    const fetchPermissions = async (companyName, profileName) => {
      try {
        setPermissionLoading(true);
        const { company_name, profile_name } = props.rowData;
        const res = await fetchPermissionsForUser(company_name, profile_name);
        const data = res.data.map((el) => ({
          ...el,
          isGranted: el.profile_permission_group_xref_id !== null,
        }));
        setOriginalPermissions(data);
        setPermissions(data);
      } catch (error) {
        props.onUpdate({
          toast: {
            title: "Update Failed",
            description: error.message,
            duration: 3,
            status: "error",
          },
        });
      } finally {
        setPermissionLoading(false);
      }
    };
    fetchPermissions();
  }, [props.rowData]);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };
  const closeModal = (e) => {
    e?.preventDefault();
    props.onClose(false);
  };

  const handleChangePermission = (index, value) => {
    setPermissions((prev) => {
      const curr = _.cloneDeep(prev);
      curr[index].isGranted = value;
      return curr;
    });
  };

  const getChangedPermissions = () => {
    const result = [];
    for (let i = 0; i < originalPermissions.length; i++) {
      if (originalPermissions[i].isGranted !== permissions[i].isGranted) {
        result.push(permissions[i]);
      }
    }
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedProfile = {
      profile_name: props.rowData?.profile_name,
      company_name: props.rowData?.company_name,
      first_name: firstName,
      last_name: lastName,
      profile_status: status,
      email_address: email,
    };
    const updatedData = {
      ...updatedProfile,
      changedPermissions: getChangedPermissions(),
    };
    try {
      const result =await updateProfile(updatedData);
      props.onUpdate({
        toast: {
          title: "Record Updated",
          description: result.message,
          duration: 3,
          status: "success",
        },
      });
      props.updateUser(updatedProfile);
      closeModal();
    } catch (error) {
      props.onUpdate({
        toast: {
          title: "Update Failed",
          description: error.message,
          duration: 3,
          status: "error",
        },
      });
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="user-settings-body">
          <div className="user-settings-info-row">
            <div className="user-settings-info-container">
              <div className="user-settings-header">
                <UserAvatarComponent name={`${fullName || ""}`} />
                <h2 className="user-settings-name">{`${fullName || ""}`}</h2>
              </div>
              <div className="edit-user-settings-row">
                <label>First Name:</label>
                <input
                  type="text"
                  value={firstName}
                  onChange={handleFirstNameChange}
                />
              </div>
              <div className="edit-user-settings-row">
                <label>Last Name:</label>
                <input
                  type="text"
                  value={lastName}
                  onChange={handleLastNameChange}
                />
              </div>
              <div className="edit-user-settings-row">
                <label>Email:</label>
                <input type="text" value={email} onChange={handleEmail} />
              </div>
              <div className="edit-user-settings-row">
                <label>Status:</label>
                <FocusInputs.Select
                  value={status}
                  onChange={handleStatusChange}
                >
                  <option value="Active">Active</option>
                  <option value="inactive">Inactive</option>
                </FocusInputs.Select>
              </div>
            </div>

            <Permissions
              permissions={permissions}
              onChange={handleChangePermission}
            />
          </div>

          <div className="edit-user-btn-container">
            <button type="submit" className="main btn">
              Save Changes
            </button>
            <button className="secondary btn" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
export default UserUpdate;
