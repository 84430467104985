export function isEmailFormat(value) {
  if (!value) {
    return false;
  }
  /**
   * Regular expression for validating email format.
   * This regex pattern checks if a string matches the typical structure of an email address.
   * It ensures the presence of a username, "@" symbol, domain name.
   */
  return /^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,3}$/
.test(value);
}
