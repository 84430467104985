import { useCallback, useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Form, Select, FocusInputs, Toast } from "@alpaca/framework";

import NameInput from "../name-input/name-input.component";

import validator from "./register-form.validators";
import { isEmailFormat } from "./register-form.util";
import isValidUser from "../../../../utils/isValidUser";

import { getCompanyTypes } from "../../../../service/companyType/getCompanyTypes";
//import checkEmail from "../../../../service/authentication/checkEmail";
import register from "../../../../service/authentication/register";

import { login } from "../../../../store/reducer/userInfoReducer";
import "./register-form.scss";

function RegistrationForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toastRef = useRef(null);
  const [form] = Form.useForm();
  const email = Form.useWatch("email", form);
  const username = Form.useWatch("username", form);

  const [isLoading, setIsLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const [companyTypes, setCompanyTypes] = useState([]);
  //const [verifiedEmails, setVerifiedEmails] = useState({});

  //application allow for duplicate emails now
  // /* Dynamically verify if email is use immediately when valid format is inputted */
  // const handleCheckEmail = useCallback(
  //   (email) => {
  //     const emailList = Object.keys(verifiedEmails);
  //     if (emailList.indexOf(email) < 0) {
  //       checkEmail(email).then((isValid) => {
  //         setVerifiedEmails((prevList) => ({
  //           ...prevList,
  //           [email]: isValid,
  //         }));
  //       });
  //     }
  //   },
  //   [verifiedEmails]
  // );

  // useEffect(() => {
  //   if (isEmailFormat(email)) {
  //     handleCheckEmail(email);
  //   }
  // }, [email, handleCheckEmail]);

  useEffect(() => {
    if (username) {
      setServerErrors({});
      form.validateFields(["username"]);
    }
  }, [username, form]);

  useEffect(() => {
    getCompanyTypes()
      .then((types) => setCompanyTypes(types))
      .catch((error) => {
        toastRef.current?.open({
          title: "Get Company Type Failed",
          description: error.message,
          duration: 3,
          status: "error",
        });
      });
  }, []);

  return (
    <Form form={form} onFinish={handleRegister} className="register-form">
      <Toast ref={toastRef} />
      <Form.Item name="username" rules={validator.username(serverErrors)}>
        <FocusInputs.Input title="User Name" required />
      </Form.Item>
      <Form.Item name="password" rules={validator.password}>
        <FocusInputs.Password title="Password" required />
      </Form.Item>
      <Form.Item name="email" rules={validator.email(true, serverErrors)}>
        <FocusInputs.Input title="Email Address" />
      </Form.Item>
      <Form.Item name="company" rules={validator.company}>
        <FocusInputs.Input title="Company Name" required />
      </Form.Item>
      <Form.Item name="companyType" rules={validator.companyType}>
        <FocusInputs.Select title="Company Type" required>
          {companyTypes.length &&
            companyTypes.map((type, i) => (
              <Select.Option key={["company-type", i]} value={type}>
                {type}
              </Select.Option>
            ))}
        </FocusInputs.Select>
      </Form.Item>
      <Form.Item name="name" rules={validator.name}>
        <NameInput required />
      </Form.Item>
      <Button
        onClick={() => form.submit()}
        loading={isLoading}
        className="register-button btn mb20"
      >
        Continue
      </Button>
      <div className="already-have-account-container">
        <p>
          Already have an account?
          <Link to="/" className="log-in-text">
            Log In
          </Link>
        </p>
      </div>
    </Form>
  );

  async function handleRegister(inputs) {
    try {
      setIsLoading(true);
      const registerResponse = await register(inputs);
      let userInfo;
      if (registerResponse.data === true) {
        userInfo = registerResponse.info;
        dispatch(login(userInfo));
        navigate("/dashboard", { replace: true });
      }
    } catch (error) {
      const message = error.message;
      if (message.includes("email") || message.includes("username")) {
        setServerErrors({
          type: message.includes("email") ? "email" : "username",
          message: message,
        });
      } else {
        toastRef.current?.open({
          title: "Register Failed",
          description: message,
          duration: 3,
          status: "error",
        });
      }
    } finally {
      setIsLoading(false);
    }
  }
}

export default RegistrationForm;
