import RegisterForm from "./components/register-form/register-form.component";

import HorizontalLogo from "../../assets/AlpacaLogo/HorizontalLogo.png";
import RegisterImg from "../../assets/Img/RegisterComponent/register-img.png";

import "./register.scss";

function Register() {
  return (
    <div className="register">
      <nav className="header">
        <img src={HorizontalLogo} alt="horizontal logo" />
      </nav>
      <div className="content">
        <div className="card">
          <div className="grid">
            <h1 className="card-title">Welcome Aboard!</h1>
            <div className="form">
              <RegisterForm />
            </div>
            <img
              src={RegisterImg}
              alt="Register Illustration"
              className="register-illustration"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
