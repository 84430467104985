import React, { Component } from "react";
import "./modulesButton.styles.css";
import "./modules.styles.css";
import "../../pages/dashboard/mainPage.styles.css";
import PlaceholderImg from "./../../assets/Img/Modules/placeholder.jpg";

export default class ModuleButton extends Component {
  render() {
    return (
      <div
        onClick={() => {
          if (this.props.disabled) {
            window.location = this.props.href;
          }
        }}
        className={
          "module-grid-item" +
          (this.props.disabled === 1 ? " gray-out-button" : "")
        }
      >
        <div className="module-grid-item-img-container">
          <img
            src={
              this.props.background?.startsWith("data")
                ? this.props.background
                : `data:image/jpeg;base64,${this.props.background}`
            }
            // src={`${this.props.background}`}
            alt="Base64 JPG -100"
            title=""
          />
          <div className="module-grid-item-icon-container">
            <img
              src={
                this.props.icon?.startsWith("data")
                  ? this.props.icon
                  : `data:image/svg+xml;base64,${this.props.icon}`
              }
              alt="Base64 SVG"
              title=""
            />
          </div>
        </div>

        <h2 className="module-btn-headline">{this.props.header}</h2>
        <p className="body-text module-body-text">{this.props.body}</p>
      </div>
    );
  }
}

ModuleButton.defaultProps = {
  name: "Undefined",
};
