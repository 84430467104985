/*Component for overlaying tooltips on top of other components */
/*Author: Wilfredo Pasadilla */
/*Version: 1.0.0 */

import React, { useState, useEffect, useRef } from "react";
import "./tooltip.styles.css";

const Tooltip = ({ children, title }) => {
  const [openTip, setOpenTip] = useState(false);

  return (
    <div className="tooltip__container">
      <div
        onMouseOver={() => {
          setOpenTip(true);
        }}
        onMouseOut={() => {
          setOpenTip(false);
        }}
      >
        {children}
      </div>

      <div
        className={openTip ? "tooltip__content" : "tooltip__content--hidden"}
      >
        {title}
      </div>
    </div>
  );
};

export default Tooltip;
