import axios from "axios";
import config from "../../config";
import { handleAxiosError } from "../error.handler.service";
export const getAppVersionNumber = async () => {
  return axios
    .get(`${config.IP_ADDRESS}/app/version`)
    .then((result) => result?.data)
    .catch((error) => handleAxiosError(error));
};

export const getAllApps = async () => {
  return axios
    .get(`${config.IP_ADDRESS}/app/appList`)
    .then((result) => result?.data)
    .catch((error) => handleAxiosError(error));
};
