import React from "react";
import "./permissionDenied.styles.css";

const SeverError = ({ errorMessage }) => {
  return (
    <div className="message">
      <h2>Server Error</h2>
      {!!errorMessage && <p>{errorMessage}</p>}
      <p>Sorry, something went wrong on your account or our server .</p>
      <p>Please try again later or contact our help desk.</p>
    </div>
  );
};

export default SeverError;
