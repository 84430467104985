import axios from "axios";
import config from "../config";
import { store } from "../store/store";
const { handleAxiosError } = require("./error.handler.service");
export const getAllModules = async () => {
  try {
    const { company_name, profile_name } = getUserInfo();
    const resp = await axios.get(`${config.IP_ADDRESS}/modules`, {
      params: { company_name, profile_name },
    });
    return await resp.data;
    // const customers = await resp.data;
    // return await customers.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

function getUserInfo() {
  const state = store.getState();
  return state?.userInfo;
}
