import { React, useEffect } from "react";
import { FocusInputs, Form, Button, Toast } from "@alpaca/framework";
import { useRef, useState } from "react";
import CompanyLogo from "../../../assets/AlpacaLogo/AlpacaHorizontalBlack.svg";
import { getWideLogoService } from "../../../service/VisualChanges/visualChanges";
import HorizontalLogo from "../../../assets/AlpacaLogo/HorizontalLogo.png";
import "./newPassword.styles.scss";
import validator from "../../../pages/register/components/register-form/register-form.validators";
import { ResetPasswordService } from "../../../service/resetPassword/resetPassword";

function SetPassword(props) {
  const [wideLogo, setWideLogo] = useState(HorizontalLogo);
  const [form] = Form.useForm();
  const newPassword = Form.useWatch("New Password", form);
  const oldPassword = Form.useWatch("Old Password", form);
  const toastRef = useRef(null);
  const handleResetPassword = () => {
    ResetPasswordService({
      oldPassword: oldPassword,
      newPassword: newPassword,
      profileInfo: {
        company_name: props.userInfo.company_name,
        profile_name: props.userInfo.profile_name,
      },
    })
      .then((res) => {
        props.showToast({
          title: res.message,
          description: res.description,
          duration: 3,
          status: "success",
        });

        props.onClose();
      })
      .catch((error) => {
        if (props.showToast) {
          props.showToast({
            title: "Reset Password Fail!",
            description: error.message,
            duration: 3,
            status: "error",
          });
        }
      });
  };
  useEffect(() => {
    getWideLogoService(props?.userInfo?.company_name)
      .then((result) => {
        if (result) {
          setWideLogo(result);
        }
      })
      .catch((error) => {
        toastRef.current?.open({
          title: "Get Company Logo failed",
          description: error.message,
          duration: 3,
          status: "error",
        });
      });
  }, []);
  const handleInputFocus = (name) => {
    form.setFields([{ name, errors: [] }]);
  };

  return (
    <>
      <Toast ref={toastRef} />
      <section className="ResetPasswordBackground">
        <img
          src={wideLogo}
          alt="Company Logo"
          className="mb20 company-logo"
          onError={(e) => {
            e.target.src = HorizontalLogo;
          }}
        />
        <div className="inputContainer">
          <div className="newPassword_container">
            <Form form={form} onFinish={handleResetPassword}>
              <Form.Item name="Old Password" validateTrigger="onSubmit">
                <FocusInputs.Password
                  title="Old Password"
                  required
                  onFocus={() => handleInputFocus("Old Password")}
                />
              </Form.Item>
              <Form.Item
                name="New Password"
                rules={validator.password}
                validateTrigger="onSubmit"
              >
                <FocusInputs.Password
                  title="New Password"
                  required
                  onFocus={() => handleInputFocus("New Password")}
                />
              </Form.Item>
              <Form.Item
                name="Confirm Password"
                rules={validator.confirmPassword(newPassword)}
                validateTrigger="onSubmit"
              >
                <FocusInputs.Password
                  title="Confirm Password"
                  required
                  onFocus={() => handleInputFocus("Confirm Password")}
                />
              </Form.Item>
            </Form>
            <br />
            <button
              type="primary"
              loading={false}
              className="main btn reset-password-btn"
              onClick={() => form.submit()}
            >
              Reset Password
            </button>
          </div>
        </div>
      </section>
    </>
  );
}

export default SetPassword;
