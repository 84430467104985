import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, Form, FocusInputs } from "@alpaca/framework";

import NameInput from "../../register/components/name-input/name-input.component";
import validator from "../../register/components/register-form/register-form.validators";
import { CreateInviteProfileService } from "../../../service/invite/invite";
import { login } from "../../../store/reducer/userInfoReducer";
import "../../register/components/register-form/register-form.scss";
//TODO find out what this file does
function InviteForm(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  return (
    <Form form={form} onFinish={handleInvite} className="register-form">
      <Form.Item
        name="username"
        rules={!props.inviteData?.profile_name ? validator.username() : ""}
        initialValue={props.inviteData?.profile_name || ""}
      >
        <FocusInputs.Input
          title="User Name"
          required
          disabled={props.inviteData?.profile_name ? true : false}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={!props.inviteData?.password ? validator.password : ""}
        initialValue={props.inviteData?.password}
      >
        {!props.inviteData?.password ? (
          <FocusInputs.Password title="Password" required visible={true} />
        ) : (
          <FocusInputs.Input title="Password" required disabled />
        )}
      </Form.Item>
      <Form.Item name="email" initialValue={props.inviteData.email_address}>
        <FocusInputs.Input disabled title="Email" />
      </Form.Item>
      <Form.Item
        name="name"
        rules={!props.inviteData?.first_name ? validator.name : ""}
        initialValue={{
          lastName: props.inviteData.last_name,
          firstName: props.inviteData.first_name,
        }}
      >
        <NameInput
          required
          disabled={props.inviteData?.first_name ? true : false}
        />
      </Form.Item>
      <Button
        onClick={() => form.submit()}
        loading={isLoading}
        className="register-button btn"
      >
        Sign Up
      </Button>
    </Form>
  );

  function handleInvite(inputs) {
    setIsLoading(true);

    CreateInviteProfileService(props.token, {
      user: inputs.username,
      firstName: inputs.name.firstName,
      lastName: inputs.name.lastName,
      email: inputs.email,
      pwd: inputs.password,
    })
      .then((res) => {
        if (res.success) {
          dispatch(login(res.data.info));
          navigate("/dashboard", { replace: true });
        } else {
          props.openToast({
            title: "Create Account fail!",
            description: res.message,
            duration: 5,
            status: "error",
          });
        }
      })
      .catch((error) => {
        props.openToast({
          title: "Create Account fail!",
          description: error.message,
          duration: 5,
          status: "error",
        });
      })
      .finally(setIsLoading(false));
  }
}
export default InviteForm;
