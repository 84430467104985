import React, { useEffect, useState, useRef } from "react";
import "./userAvatar.styles.css";
import { Toast } from "@alpaca/framework";
export function UserAvatarComponent(props) {
  const toastRef = useRef(null);

  function toInitials(name) {
    const nameSections = name.split(/\s/g);
    const sections = nameSections.length;

    if (sections === 1) {
      return name.charAt(0);
    }
    if (sections > 0) {
      return nameSections[0].charAt(0) + nameSections[sections - 1].charAt(0);
    }
    return "";
  }

  const handleAvatarClick = () => {
    //click
  };

  const handleUpdate = () => {
    toastRef.current.open({
      title: "Record Updated",
      description: "your record has been updated",
      duration: 3,
      status: "success",
    });
  };

  return (
    <>
      <Toast ref={toastRef} />
      <div
        className={`userAvatar ${props.className}`}
        id="avatar"
        onClick={props.clickable ? handleAvatarClick : null}
      >
        {toInitials(props.name)}
      </div>
    </>
  );
}

UserAvatarComponent.defaultProps = {
  name: "",
};

export default UserAvatarComponent;
