import { createSlice } from "@reduxjs/toolkit";

const visualSlice = createSlice({
  name: "visualSetting",
  initialState: {
    color_main: null,
    color_secondary: null,
    color_tertiary: null,
    logo_small: null,
    logo_wide: null,
    logo_label:null,
  },
  reducers: {
    setVisualSetting: (state, action) => {
      state.color_main = action.payload.color_main;
      state.color_secondary = action.payload.color_secondary;
      state.color_tertiary = action.payload.color_tertiary;
      state.logo_small = action.payload.logo_small;
      state.logo_wide = action.payload.logo_wide;
      state.logo_label = action.payload.logo_label;
    },
  },
});
export const { setVisualSetting } = visualSlice.actions;
export const visualReducer = visualSlice.reducer;
