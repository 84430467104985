import React, { useState, useRef, useEffect } from "react";
import { UserInfoSelector } from "../../store/reduxSelector";
import {
  CheckEmailService,
  CancelInvitationService,
  ResendInvitationService,
  ForceCreateInvitationService,
} from "../../service/invite/invite";
import { Button } from "@alpaca/framework";
import { isEmailFormat } from "../../pages/register/components/register-form/register-form.util";
import "../userUpdate/userUpdate.styles.scss";

export function InvitationWindow(props) {
  const user = UserInfoSelector();
  const [email, setEmail] = useState(props.rowData?.email_address);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [profileName, setProfileName] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!!props.resendInvitation) {
      await resendSubmit(e);
      setLoading(false);
    } else if (!!props.cancelInvitation) {
      await cancelSubmit(e);
      setLoading(false);
    } else if (!!props.forceCreateInvitation) {
      await forceCreateSubmit(e);
      setLoading(false);
    } else {
      setLoading(false);
      return null;
    }
  };

  const resendSubmit = async (e) => {
    if (!isEmailFormat(email)) {
      props.onFinish({
        toast: {
          title: "Invalid Email",
          description: "Please check the email and try again",
          duration: 3,
          status: "error",
        },
      });
      return;
    }

    if (email !== props.rowData?.email_address) {
      try {
        const result = await CheckEmailService(email, user.company_name);
        //if email changed check email and update first
        //if email already exist toast error message and exist
        if (!result) {
          props.onFinish({
            toast: {
              title: "Resend Failed",
              description:
                "Email already in Use, please change to another email",
              duration: 3,
              status: "error",
            },
          });
          return;
        }
      } catch (error) {
        props.onFinish({
          toast: {
            title: "Resend Failed",
            description: error.message,
            duration: 3,
            status: "error",
          },
        });
        return;
      }
    }
    try {
      //then resend email
      const result = await ResendInvitationService(
        user.profile_name,
        user.company_name,
        props.rowData?.email_address,
        email
      );
      if (result.success) {
        props.onFinish({
          toast: {
            title: "Resend successful",
            description: result.description,
            duration: 3,
            status: "success",
          },
        });
        props.resendInvitation({
          ...props.rowData,
          newEmail: email,
        });
        closeModal(e);
      } else {
        props.onFinish({
          toast: {
            title: "Resend Failed",
            description: result.description,
            duration: 3,
            status: "error",
          },
        });
      }
    } catch (error) {
      props.onFinish({
        toast: {
          title: "Resend Failed",
          description: error.message,
          duration: 3,
          status: "error",
        },
      });
    }
  };
  const cancelSubmit = async (e) => {
    try {
      const result = await CancelInvitationService(
        props.rowData?.email_address,
        props.rowData?.company_name
      );
      if (result) {
        props.onFinish({
          toast: {
            title: "Cancel successful",
            description: "Invitation Cancel successful",
            duration: 3,
            status: "success",
          },
        });
        props.cancelInvitation({
          ...props.rowData,
        });
        closeModal(e);
      } else {
        props.onFinish({
          toast: {
            title: "Cancel Failed",
            description: "Cancel Invitation Failed, Please try again",
            duration: 3,
            status: "error",
          },
        });
      }
    } catch (error) {
      props.onFinish({
        toast: {
          title: "Cancel Failed",
          description: error.message,
          duration: 3,
          status: "error",
        },
      });
    }
  };

  const forceCreateSubmit = async (e) => {
    if (profileName.length === 0) {
      props.onFinish({
        toast: {
          title: "Empty Profile Name",
          description: "Please enter the profile name",
          duration: 3,
          status: "error",
        },
      });
      return;
    }
    try {
      const result = await ForceCreateInvitationService({
        ...props.rowData,
        profile_name: profileName,
        first_name: firstName,
        last_name: lastName,
      });

      props.onFinish({
        toast: {
          title: result.message,
          description: result.description,
          duration: 3,
          status: "success",
        },
      });
      props.forceCreateInvitation({
        ...props.rowData,
        current_environment_id: null,
        profile_status: "active",
        profile_name: profileName,
        first_name: firstName,
        last_name: lastName,
      });
      closeModal(e);
    } catch (error) {
      props.onFinish({
        toast: {
          title: "Force Create User Fail!",
          description: error.message,
          duration: 3,
          status: "error",
        },
      });
    }
  };

  const closeModal = (e) => {
    setLoading(false);
    e?.preventDefault();
    props.onClose(false);
  };

  const generateMessage = () => {
    if (!!props.resendInvitation) {
      return "Are you sure you want to resend this invitation?";
    } else if (!!props.cancelInvitation) {
      return "Are you sure you want to cancel this invitation?";
    } else if (!!props.forceCreateInvitation) {
      return "Are you sure you want to create this user?";
    } else {
      return null;
    }
  };

  const generateButtonName = () => {
    if (!!props.resendInvitation) {
      return "Resend";
    } else if (!!props.cancelInvitation) {
      return "Cancel Invitation";
    } else if (!!props.forceCreateInvitation) {
      return "Create";
    } else {
      return null;
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="user-settings-body">
          <div className="edit-user-settings-row">
            <label>Email:</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={!props.resendInvitation}
            />
          </div>
          {props.forceCreateInvitation && (
            <>
              <div className="edit-user-settings-row">
                <label>Profile Name:</label>
                <input
                  required
                  type="text"
                  value={profileName}
                  onChange={(e) => setProfileName(e.target.value)}
                />
              </div>
              <div className="edit-user-settings-row">
                <label>First Name:</label>
                <input
                  required
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="edit-user-settings-row">
                <label>Last Name:</label>
                <input
                  required
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </>
          )}

          <p className="warning-text-delete">{generateMessage()}</p>

          <div className="edit-user-btn-container">
            <Button
              onClick={handleSubmit}
              className="main btn"
              loading={loading}
            >
              {generateButtonName()}
            </Button>
            <Button
              className="secondary btn"
              onClick={closeModal}
              disabled={loading}
            >
              Close
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
export default InvitationWindow;
