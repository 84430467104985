import React, { Component } from "react";
import "./settings.styles.css";
import LogoIcon from "../../../assets/AlpacaLogo/LogoIcon.png";
import LogoHorizontal from "../../../assets/AlpacaLogo/HorizontalLogo.png";
import CustomizationSettings from "./customizationSettings.component";
import ProfileSettings from "./profileSettings.component";
import UserSettingsTabs from "./UserSettingsTabs/UserSettingsTabs.component";
import ModulesHeaderImg from "./../../../assets/Img/Modules/modules-header-img.svg";
import { SidebarLayout } from "@alpaca/framework";

export default class Settings extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <section className="settings-header">
          <h1 className="settings-welcome-headline">Settings</h1>
          <img
            src={ModulesHeaderImg}
            alt="dashboard modules graphic"
            className="modules-header-img"
          />
        </section>

        <UserSettingsTabs />
      </>
    );
  }
}
