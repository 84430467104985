import axios from "axios";
import config from "../../config";
const ROUTER_NAME = "invite";
const { handleAxiosError } = require("../error.handler.service");
export const sendInvitationService = async (inviteData) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/create`,
      inviteData
    );
    return resp.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const ValidateInviteTokenService = async (token) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/authorization/validateToken`,
      {
        token: token || "",
      }
    );
    return resp.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const CreateInviteProfileService = async (token, profileData) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/authorization/createProfile`,
      {
        token: token || "",
        profileData: profileData,
      }
    );
    return resp?.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const CheckEmailService = async (newEmail, company_name) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/checkEmail`,
      {
        newEmail: newEmail,
        company_name: company_name,
      }
    );
    return resp?.data;
  } catch (error) {
    // Handle Error Here
    handleAxiosError(error);
  }
};

export const CancelInvitationService = async (email_address, company_name) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/cancel`,
      {
        email_address,
        company_name,
      }
    );

    return resp.data;
  } catch (error) {
    // Handle Error Here
    handleAxiosError(error);
  }
};

export const ResendInvitationService = async (
  profile_name,
  company_name,
  oldEmail,
  newEmail
) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/resend`,
      {
        profile_name,
        company_name,
        oldEmail,
        newEmail,
      }
    );

    return resp.data;
  } catch (error) {
    // Handle Error Here
    handleAxiosError(error);
  }
};

export const ForceCreateInvitationService = async (profileData) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/forceCreate`,
      {
        profileData: profileData,
      }
    );

    return resp.data;
  } catch (error) {
    // Handle Error Here
    handleAxiosError(error);
  }
};
