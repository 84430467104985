// keys - must be an array, if empty array, it will accept all keys
export function sieveObjectByKeys(objects, search, keys = []) {
  // search must be specified to sieve
  if (!search || search?.length === 0) {
    return objects;
  }

  return objects.filter((obj) => {
    let searchables = [];

    if (keys.length === 0) {
      searchables = Object.values(obj);
    } else {
      let entries = Object.entries(obj);

      // calculate searchables
      searchables = entries.reduce((searchable, [key, value]) => {
        if (keys.indexOf(key) >= 0) {
          searchable.push(value);
        }
        return searchable;
      }, []);
    }

    return searchables.some((value) => {
      if (typeof value !== "string") {
        return false;
      }
      return value.toLowerCase().includes(search.toLowerCase());
    });
  });
}
