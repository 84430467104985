import axios from "axios";
import config from "../../config";
const ROUTER_NAME = "environment";
const { handleAxiosError } = require("../error.handler.service");
export const getBCResource = async (companyName) => {
  try {
    const resp = await axios.get(`${config.IP_ADDRESS}/${ROUTER_NAME}`, {
      params: { company_Name: companyName },
    });
    return resp.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const getAllEnvironment = async (companyName) => {
  try {
    const resp = await axios.get(`${config.IP_ADDRESS}/${ROUTER_NAME}`, {
      params: { company_Name: companyName },
    });
    return resp.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const getTenantInfo = async (companyName) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/getTenantInfo`,
      {
        company_Name: companyName,
      }
    );
    if (resp.data.length > 0) {
      return resp.data[0];
    }
    return null;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const getSPInfo = async (companyName) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/getSPInfo`,
      {
        company_Name: companyName,
      }
    );
    if (resp.data.length > 0) {
      return resp.data[0];
    }
    return null;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const getTenantIdByDomainName = async (domainName) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/getTenantIdByDomainName`,
      {
        domainName: domainName,
      }
    );
    return resp.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const updateTenantInfo = async (updateData) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/tenantInfo`,
      updateData
    );
    return true;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const getBCCompanyList = async (tenantData) => {
  try {
    const { company_name, ...otherData } = tenantData;
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/getCompanyNames`,
      tenantData
    );

    return resp.data.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const verifySPTenantInfo = async (tenantData) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/verifySPTenantInfo`,
      tenantData
    );

    return resp.data.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const saveBCEnvironment = async (data) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/saveBCEnvironment`,
      data
    );
    return true;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const saveSPEnvironment = async (data) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/saveSPEnvironment`,
      data
    );

    return true;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};
