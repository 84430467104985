import React from "react";
import "./moduleheader.styles.css";
import ModulesHeaderImg from "./../../assets/Img/Modules/modules-header-img.svg";
import { AiOutlineDownload } from "react-icons/ai";
import { UserInfoSelector } from "../../store/reduxSelector";

export function ModulesHeaderComponent(props) {
  const user = UserInfoSelector();

  return (
    <>
      <section className="modules-header">
        <h1 className="modules-welcome-headline">
          Welcome Back, {user.firstName || ""}
        </h1>
        <img
          src={ModulesHeaderImg}
          alt="dashboard modules graphic"
          className="modules-header-img"
        />
        <div className="installed-apps-btn-container">
          <button className="installed-apps-btn">
            <AiOutlineDownload />
            Installed Apps
          </button>
        </div>
      </section>
    </>
  );
}

export default ModulesHeaderComponent;
