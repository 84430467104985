import { deleteProfile } from "../../service/profile/profile";
import UserAvatarComponent from "../avatar/userAvatar.component";
import "../userUpdate/userUpdate.styles.scss";

export function UserDelete(props) {
  const fullName = `${props.rowData?.first_name || ""} ${
    props.rowData?.last_name || ""
  }`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      profile_name: props.rowData?.profile_name,
      company_name: props.rowData?.company_name,
    };
    try {
      let result = await deleteProfile(data);
      props.onDelete({
        toast: {
          title: "Record Deleted",
          description: result.message,
          duration: 3,
          status: "success",
        },
      });
      props.removeUser(data);
      closeModal(e);
    } catch (error) {
      props.onDelete({
        toast: {
          title: "Delete Failed",
          description: error.message,
          duration: 3,
          status: "error",
        },
      });
    }
  };

  const closeModal = (e) => {
    e?.preventDefault();
    props.onClose(false);
  };
  return (
    <>
      <div className="user-settings-header">
        <UserAvatarComponent name={`${fullName || ""}`} />
        {/* The user's full name */}
        <h2 className="user-settings-name">{`${fullName || ""}`}</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="user-settings-body">
          <div>
            <table>
              <tbody>
                <tr>
                  <td>Profile Name:</td>
                  <td style={{ paddingLeft: "1rem" }}>
                    {props.rowData?.profile_name}
                  </td>
                </tr>
                <tr>
                  <td>First Name:</td>
                  <td style={{ paddingLeft: "1rem" }}>
                    {props.rowData?.first_name}
                  </td>
                </tr>
                <tr>
                  <td>Last Name:</td>
                  <td style={{ paddingLeft: "1rem" }}>
                    {props.rowData?.last_name}
                  </td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td style={{ paddingLeft: "1rem" }}>
                    {props.rowData?.email_address}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="warning-text-delete">
            Are you sure you want to delete this user?
          </p>

          <div className="edit-user-btn-container">
            <button type="submit" className="main btn">
              Delete
            </button>
            <button className="secondary btn" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
export default UserDelete;
