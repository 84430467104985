import config from "../../config";
import axios from "axios";
const ROUTER_NAME = "profile";
const { handleAxiosError } = require("../error.handler.service");
export async function RequestResetService(value) {
  try {
    const { data: result } = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/authorization/requestReset`,
      value
    );
    return { success: false };
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
}

export const ValidateTokenService = async (token) => {
  return axios
    .post(`${config.IP_ADDRESS}/${ROUTER_NAME}/authorization/validateToken`, {
      token,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      // Handle Error Here
      handleAxiosError(error);
    });
};

export const UpdatePasswordService = async (password, token) => {
  return axios
    .post(`${config.IP_ADDRESS}/${ROUTER_NAME}/authorization/updatePassword`, {
      password,
      token,
    })
    .then(({ data: result }) => {
      return {
        success: true,
        title: "Reset password successful",
        description:
          "Password reset successful, you can use new password to login",
      };
    })
    .catch((error) => {
      handleAxiosError(error);
    });
};

export const ResetPasswordService = async (data) => {
  return axios
    .post(`${config.IP_ADDRESS}/${ROUTER_NAME}/resetPassword`, data)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      handleAxiosError(error);
    });
};
