import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const initialState = null;

const userInfo = createSlice({
  name: "userInfo",
  initialState: initialState,
  reducers: {
    login: (state, action) => {
      return action.payload;
    },
    logout: (state) => {
      const domain = window.location.hostname;
      const parts = domain.split("polarbear");
      const cookieDomain = parts.length === 2 ? parts[1] : "." + domain;
      Cookies.remove("alpaca_jwt", {
        domain: cookieDomain,
      });
      return null;
    },
  },
});
export const { login, logout } = userInfo.actions;
export const userInfoReducer = userInfo.reducer;
