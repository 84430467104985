import { Icon, StatusBadge } from "@alpaca/framework";
import DeviceItem from "./deviceItem.component";
import EditButton from "../../../components/editUserButton/editUserButton.component";

import { checkPermission } from "../../../service/permission/permission";

const columns = ({ onEdit, onDelete }) => [
  {
    title: "Device Name",
    dataIndex: "device_name",
    key: "name",
    sorter: (a, b) => a.name?.localeCompare(b.name),
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "Allowed Apps", //inside this coloumn would be DisplayAppList components
    dataIndex: "appUsed",
    key: "apps",
    sorter: (a, b) => a.apps?.localeCompare(b.apps),
    sortDirections: ["descend", "ascend"],
    render: (text, record) => <DeviceItem key={1} device={record} />,
  },
  {
    title: "Status",
    dataIndex: "device_status",
    key: "device_status",
    sorter: (a, b) => a.device_status?.localeCompare(b.device_status),
    sortDirections: ["descend", "ascend"],
    render: (text) => (
      <StatusBadge
        className="status-badge"
        icon={createStatusIcon(text.toLowerCase())}
        name={text ? text : "NO STATUS"}
        color={createStatusColor(text.toLowerCase())}
      />
    ),
  },
  ...(checkPermission("modify user")
    ? [
        {
          title: <span>Actions</span>, //without filter, the font is bolded
          dataIndex: "Edit",
          key: "dataSource",
          sortable: false,
          filterable: false,
          render: (_, record) =>
            record.device_status !== "Invite" && (
              <EditButton showEditModal={onEdit} showDeleteModal={onDelete} />
            ),
        },
      ]
    : []),
];

function createStatusColor(status) {
  switch (status) {
    case "inactive":
      return "#ec2027";
    case "active":
      return "#8cc640";
    default:
      return "#8c8c8c";
  }
}

function createStatusIcon(status) {
  switch (status) {
    case "inactive":
      return <Icon icon="close" />;
    case "active":
      return <Icon icon="check" />;
    default:
      return <Icon icon="question" />;
  }
}

export default columns;
