// Old structure : {..., userInfo: {userInfo: {}}}
// New structure : {..., userInfo: {}}

import { login } from "./userInfoReducer";

// Redux action creator
export const migrateUserDataIfNeeded = () => (dispatch, getState) => {
  const state = getState();
  const oldUserInfo = state?.userInfo?.userInfo;

  if (oldUserInfo) {
    dispatch(login(oldUserInfo));
  }
};
