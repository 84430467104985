import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import { Select, Button, Input, Icon } from "@alpaca/framework";
import { getCompanyLogos } from "../../service/company/company";
import { CheckCompanyService } from "../../service/MFA/mfa";
import companyImg from "../../assets/AlpacaLogo/mark_.jpg";
import { Divider, Space } from "antd";
const CompanySelector = ({
  companyList,
  currentSelectedCompany,
  changeCurrentCompany,
  showError,
  disabled,
}) => {
  const [companyListWithLogo, setCompanyListWithLogo] = useState([]);
  const [searchCompany, setSearchCompany] = useState("");
  useEffect(() => {
    const fetchCompanyLogos = async () => {
      const companiesWithLogos = await getCompanyLogos(companyList);
      setCompanyListWithLogo(companiesWithLogos);
    };

    fetchCompanyLogos();
  }, [companyList]);

  const handleCompanyChange = (value) => {
    changeCurrentCompany(value);
  };

  const addItem = async (e) => {
    try {
      e.preventDefault();
      const newCompany = await CheckCompanyService(searchCompany);
      if (newCompany) {
        const matchedCompany = companyListWithLogo.find(
          (ele) => ele.company_name === newCompany.company_name
        );
        if (!matchedCompany) {
          setCompanyListWithLogo([...companyListWithLogo, newCompany]);
          changeCurrentCompany(newCompany.company_name);
        } else {
          showError("This Company is already in the list");
        }
      }
    } catch (error) {
      showError(error.message);
    } finally {
      setSearchCompany("");
    }
  };

  const removeCompany = (companyName) => {
    const newCompanyList = companyListWithLogo.filter(
      (ele) => ele.company_name !== companyName
    );
    removeFromCookie(companyName);
    if (currentSelectedCompany === companyName) {
      changeCurrentCompany("");
    }
    setCompanyListWithLogo(newCompanyList);
  };

  const removeFromCookie = (company_name) => {
    const prevList = JSON.parse(Cookies.get("pulseLoggedInCompanies") || "[]");
    const newList = prevList.filter((ele) => ele !== company_name);
    Cookies.set("pulseLoggedInCompanies", JSON.stringify(newList));
  };

  return (
    <Select
      optionLabelProp="label"
      value={currentSelectedCompany}
      disabled={disabled}
      className="company-selector-outer"
      onChange={handleCompanyChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider />
          <Space>
            <Input
              placeholder="Search for Company"
              value={searchCompany}
              onChange={(e) => setSearchCompany(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
            <Button
              type="text"
              Icon={<Icon icon="plus" className="icon-btn" />}
              onClick={addItem}
            >
              Add item
            </Button>
          </Space>
        </>
      )}
    >
      {companyListWithLogo.map((ele, index) => (
        <Select.Option
          key={index}
          value={ele.company_name}
          label={
            <div className="company-mark">
              <img
                src={ele.logo_small}
                alt="Company Logo"
                className="company-selector"
                onError={(e) => {
                  e.target.src = companyImg;
                }}
              />
              <span className="company-text">{ele.company_name}</span>
            </div>
          }
        >
          <div className="company-mark">
            <img
              src={ele.logo_small}
              alt="Company Logo"
              className="company-selector"
              onError={(e) => {
                e.target.src = companyImg;
              }}
            />
            <span className="company-text">{ele.company_name}</span>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                removeCompany(ele.company_name);
                // Implement removeCompany functionality if needed
              }}
            >
              <Icon icon="x" />
            </Button>
          </div>
        </Select.Option>
      ))}
    </Select>
  );
};

export default CompanySelector;
