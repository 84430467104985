import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Popconfirm, Icon, Toast } from "@alpaca/framework";
import { Modal } from "@alpaca/framework";
import React, { Component } from "react";
import withRouter from "../../../../helpers/withRouter";
import AppList from "./appList.component";
import UserList from "./userList.component";
import UptimeLog from "./uptimeLog.component";
import { connect } from "react-redux";
import {
  deleteDevice,
  updateDevice,
} from "../../../../service/devices/deviceServices";
import EditParagraph from "../../../../components/editParagraph/editParagraph.component";
import "./editDevice.styles.css";

class EditDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandStatus: true,
      showAppModal: false,
      showUserModal: false,
      showUptimeModal: false,
      showModal: true,
      device: null,
      open: false,
      confirmLoading: false,
      redirect: false,
    };
    this.toastRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ device: this.props.location.state });
  }

  profileList = (newDevice) => {
    this.setState({ device: newDevice });
  };
  appList = (newDevice) => {
    this.setState({ device: newDevice });
  };

  saveChanges = async () => {
    await updateDevice(this.state.device, this.props.userInfo?.company_names);
    this.setState({ redirect: true });
  };

  async deleteDevice(deviceId) {
    this.setState({ confirmLoading: true });
    await deleteDevice(deviceId, this.props.userInfo?.company_names);
    this.setState({ confirmLoading: false });
    this.setState({ open: false });
    this.setState({ redirect: true });
  }

  showPopConfirm(e) {
    this.setState({ open: true });
  }

  handleCancel() {
    this.setState({ open: false });
  }

  handleFieldSave = (name, newValue) => {
    this.setState({ device: { ...this.state.device, device_name: newValue } });
  };

  openToast = (toastData) => {
    this.toastRef.current?.open(toastData);
  };
  
  render() {
    if (this.state.redirect) {
      return <Navigate to="/dashboard/Devices" />;
    }

    return (
      <div>
        <>
          <Toast ref={this.toastRef} />
          {/** Cards on the table, I don't have time to deal with this.
			 *but if someone later can find out why this doesnt render
			  if i remove sidebar items condition that'd be great. */}
          <section className="edit-devices-header">
            <h1 className="devices-welcome-headline">Edit Device</h1>
          </section>
          <div className="edit-devices-header-small">
            <p />
            <Link to="/dashboard/Devices">
              <button className="main btn device-settings-button">
                <Icon icon="back" className="device-item-btn-icon" />
                Back
              </button>
            </Link>
            <Popconfirm
              title="delete device"
              open={this.state.open}
              okButtonProps={{ loading: this.state.confirmLoading }}
              description={`are you sure you want to delete this device ${this.state.device?.device_name}`}
              onCancel={() => this.handleCancel()}
              onConfirm={() => this.deleteDevice(this.state.device)}
            >
              {/* <Link to="/dashboard/Devices"> */}
              <button
                className="main btn device-settings-button-full"
                onClick={(e) => this.showPopConfirm(e)}
              >
                <Icon icon="trash" className="device-item-btn-icon" />
                Delete
              </button>
              {/* </Link> */}
            </Popconfirm>
          </div>
          <div className="edit-device-main">
            <div className="edit-device-text-container">
              <EditParagraph
                className="edit-device-name"
                name="Device Name"
                value={this.state.device?.device_name}
                onSave={this.handleFieldSave}
              />
            </div>
            {/* <h1>
								Calgary, AB
								<span className="log-in-text pointer">
									&nbsp;&nbsp;Not Correct?
								</span>
							</h1> */}
          </div>
          <div className="edit-device-secondary">
            <div className="edit-device-text-container">
              <p className="body-text edit-device-subtitle">Surface Pro 3</p>
              <div className="edit-device-spacer" />
            </div>
            <div></div>
            <div className="edit-device-text-container">
              <p className="edit-device-text">
                Locked to
                <span className="edit-device-text secondary-color ">
                  &nbsp;{this.state.device?.appUsed?.length}&nbsp;
                </span>
                Apps
              </p>
            </div>
            <button
              className="main btn"
              onClick={() => this.setState({ showAppModal: true })}
            >
              Edit
            </button>
            <div className="edit-device-text-container">
              <p className="edit-device-text">
                Whitelisted to
                <span className="edit-device-text secondary-color ">
                  &nbsp;{this.state.device?.profile?.length}&nbsp;
                </span>
                Users
              </p>
            </div>
            <button
              className="main btn"
              onClick={() => this.setState({ showUserModal: true })}
            >
              Edit
            </button>
            <div className="edit-device-text-container">
              <p className="edit-device-text">View Uptime Log</p>
            </div>
            <button
              className="main btn"
              onClick={() => this.setState({ showUptimeModal: true })}
            >
              View
            </button>
          </div>
          <Modal
            open={this.state.showAppModal}
            title="Lock to App"
            width={800}
            height={800}
            onCancel={() => this.setState({ showAppModal: false })}
            children={
              <AppList device={this.state.device} onData={this.appList} />
            }
          />
          <Modal
            open={this.state.showUserModal}
            title="User Whitelist"
            width={800}
            height={800}
            onCancel={() => this.setState({ showUserModal: false })}
            children={
              <UserList
                device={this.state.device}
                onData={this.profileList}
                userInfo={this.props.userInfo}
                openToast={this.openToast}
              />
            }
          />
          <Modal
            open={this.state.showUptimeModal}
            title="Uptime Log"
            width={800}
            height={800}
            onCancel={() => this.setState({ showUptimeModal: false })}
            children={<UptimeLog />}
          />
          {/* <Link to="/dashboard/Devices"> */}
          <button className="main btn save-button" onClick={this.saveChanges}>
            Save
          </button>
          {/* </Link> */}
        </>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

export default connect(mapStateToProps)(withRouter(EditDevice));
