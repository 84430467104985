import React from "react";
import Tooltip from "../../../../components/tooltip/tooltip.component";
function DisplayAppList(props) {
    return (
        <div
            className="device-item-app-container"
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(5,0fr)",
                gridAutoRows: "10px",
            }}
        >
            {/* <div style={{ overflowX: "scroll" }}> */}
            {props.apps && props.apps.map((app, i) => (
                <Tooltip title={app.app_name} key={i}>
                    <img
                        src={`data:image/svg+xml;base64,${app.app_icon}`}
                        style={{width: "40px"}}
                        alt={"icon image"}
                    />
                </Tooltip>
            ))}
            {/* </div> */}
        </div>
    );
}
export default DisplayAppList;
