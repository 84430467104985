import "./settings.styles.css";
import { Button, Tooltip } from "@alpaca/framework";
import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import UserAvatarComponent from "../../../components/avatar/userAvatar.component";
import EditParagraph from "../../../components/editParagraph/editParagraph.component";
import { updateOwnProfile } from "../../../service/profile/profile";
import {
  getAuthenticatorService,
  regenerateAuthenticatorService,
} from "../../../service/MFA/mfa";
import {
  Toast,
  Accordion,
  Modal,
  Icon,
  Form,
  FocusInputs,
  Select,
} from "@alpaca/framework";
import {
  login as userLogin,
  logout as userLogout,
} from "../../../store/reducer/userInfoReducer";
import { UserInfoSelector } from "../../../store/reduxSelector";
import Settings from "./settings.component";
import SetPassword from "../../../components/ForgotPassword/ForgotPasswordComponents/SetPassword.component";
import Authenticator from "../../../components/authenticator/authenticator.component";
import EnvironmentGrid from "../../../components/userProfile/environmentGrid.component";
import { useNavigate } from "react-router-dom";
export function ProfileSettings(props) {
  const user = UserInfoSelector();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const textvalueFirstName = Form.useWatch("FirstName", form);
  const textvalueLastName = Form.useWatch("LastName", form);
  const textvalueEmail = Form.useWatch("Email", form);
  const [textvalueCompany, setTextValueCompany] = useState(user.company);
  const [textvalueEnvironment, setTextValueEnvironment] = useState(
    user.current_environment
  );
  const [authenticatorQRCode, setAuthenticatorQRCode] = useState(null);
  const { Panel } = Accordion;
  const [modalOpen, setModalOpen] = useState(false);
  const [authenticatorModalOpen, setAuthenticatorModalOpen] = useState(false);
  const navigate = useNavigate();
  const toastRef = useRef(null);

  function closeModal() {
    props.onClose(false);
  }

  function logout() {
    navigate("/");
    dispatch(userLogout());
  }

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  function onEnvironmentSelect(environment) {
    setTextValueEnvironment(environment);
  }

  async function save() {
    let data = {};
    data["profile_name"] = user.username;
    data["company_name"] = user.company;
    // data["Title"] = textvalue;
    data["email_address"] = textvalueEmail;
    data["first_name"] = textvalueFirstName;
    data["last_name"] = textvalueLastName;
    data["current_environment_id"] =
      textvalueEnvironment?.environment_id || null;
    //data["Phone"] = textvaluePhone;
    try {
      let result = await updateOwnProfile(data);
      if (result.status) {
        toastRef.current.open({
          title: "Record Updated",
          description: "Profile Saved",
          duration: 3,
          status: "success",
        });
        dispatch(
          userLogin({
            ...user,
            email: textvalueEmail,
            current_environment: textvalueEnvironment,
            firstName: textvalueFirstName,
            lastName: textvalueLastName,
          })
        );
      }
    } catch (error) {
      toastRef.current.open({
        title: "Record Updated fail",
        description: error.message,
        duration: 3,
        status: "error",
      });
    }
  }

  const showToast = (toastData) => {
    toastRef.current.open(toastData);
  };

  // if (isModalOpen) {
  //   return (
  //     <>
  //       <Toast ref={toastRef} />
  //       <div className="optional-modal">
  //         <SetPassword
  //           userInfo={user}
  //           onClose={handleCloseModal}
  //           showToast={showToast}
  //         />
  //       </div>
  //     </>
  //   );
  // }

  return (
    <div className="full-height">
      <Settings />
      <Toast ref={toastRef} />
      <Modal
        open={modalOpen}
        width={700}
        height={700}
        title={"Reset Password"}
        onCancel={() => setModalOpen(false)}
        children={
          <div className="optional-modal">
            <SetPassword
              userInfo={user}
              onClose={handleCloseModal}
              showToast={showToast}
            />
          </div>
        }
      />
      <Modal
        open={authenticatorModalOpen}
        width={700}
        height={700}
        title={"Authenticator QR Code"}
        onCancel={() => setAuthenticatorModalOpen(false)}
        children={
          <div className="optional-modal">
            <Authenticator
              userInfo={user}
              onClose={() => setAuthenticatorModalOpen(false)}
            />
          </div>
        }
      />
      <div className="profile-settings-grid">
        <div className="head-btn-container">
          <div className="head-btn-subheader">
            <h1>Edit Profile</h1>
            <p>Change your information and environment.</p>
          </div>
        </div>
        <>
          <Toast ref={toastRef} />
          <div className="profile-settings-header-outer">
            <UserAvatarComponent
              className="profile-avatar"
              name={`${user.firstName || ""} ${user.lastName || ""}`}
            />
            <div className="profile-settings-header">
              {/* The user's full name */}
              <h3 className="profile-settings-name">{`${user.firstName || ""} ${
                user.lastName || ""
              }`}</h3>
              {/* <p className="alttext">Cairns</p> */}
              <div className="profile-badge">
                {/* This would show what type of the user is. EX: Admin, Member, Viewer */}
                <p>{user.role || ""}</p>
              </div>
            </div>
          </div>
          <div className="profile-settings-body">
            <div className="profile-settings-body">
              <div style={{ display: "flex", gap: "10px" }}>
                <button
                  className="secondary btn mb20"
                  onClick={handleOpenModal}
                >
                  Change Password
                </button>
                <button
                  className="secondary btn mb20"
                  onClick={() => {
                    setAuthenticatorModalOpen(true);
                  }}
                >
                  Authenticator QR Code
                </button>
              </div>
              {/* <div className="head-btn-container">
                <h1>Info</h1>
              </div> */}
              <Form form={form} onFinish={save}>
                <div className="user-settings-row-wide">
                  {/* <EditParagraph
                  name="First Name"
                  value={textvalueFirstName}
                  onSave={handleFieldSave}
                /> */}
                  <Form.Item name="FirstName" initialValue={user.firstName}>
                    <FocusInputs.Input
                      title="First Name"
                      className="user-input"
                      required
                    />
                  </Form.Item>
                  {/* <EditParagraph
                  name="Last Name"
                  value={textvalueLastName}
                  onSave={handleFieldSave}
                /> */}
                  <Form.Item initialValue={user.lastName} name="LastName">
                    <FocusInputs.Input
                      title="Last Name"
                      value={user.lastName}
                      className="user-input"
                      required
                    />
                  </Form.Item>
                </div>

                <Form.Item initialValue={user.email} name="Email">
                  <div className="user-settings-row">
                    {/* <EditParagraph
                  cantEdit={true}
                  name="Email"
                  value={textvalueEmail}
                /> */}
                    <FocusInputs.Input
                      title="Email"
                      value={textvalueEmail}
                      className="user-input"
                      required
                    />
                  </div>
                </Form.Item>

                <div className="user-settings-row">
                  {/* <EditParagraph
                  cantEdit={true}
                  name="Environment"
                  // value={
                  //   textvalueEnvironment
                  //     ? textvalueEnvironment.environment_name
                  //     : ""
                  // }
                /> */}
                  <EnvironmentGrid
                    environmentInfo={textvalueEnvironment}
                    onSelect={onEnvironmentSelect}
                  />
                </div>
              </Form>
              <div className="profile-btn-container mb20">
                <button className="main btn" onClick={save}>
                  Save Changes
                </button>
                <button className="secondary btn" onClick={logout}>
                  Log Out
                </button>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export default ProfileSettings;
