import React from "react";
import "./environmentSelector.styles.css";
import "./environmentGrid.styles.scss";
import { Select, FocusInputs } from "@alpaca/framework";
import { useEffect, useState } from "react";
import { getAllEnvironment } from "../../service/environment/environment";
import { useSelector } from "react-redux";

const { Option } = Select;

function EnvironmentGrid({ environmentInfo, onSelect }) {
  const userInfo = useSelector((state) => state.userInfo);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedEnvId, setSelectedEnvId] = useState(null);
  const [envOptions, setEnvOptions] = useState([]);
  const [loadingDropdownOptions, setLoadingDropdownOptions] = useState(false);

  useEffect(() => {
    getEnvironments(userInfo.company);
  }, [userInfo.company]);

  useEffect(() => {
    if (environmentInfo && envOptions) {
      const index = envOptions.findIndex(
        (el) =>
          Number(el.environment_id) === Number(environmentInfo.environment_id)
      );
      if (index !== -1) {
        setSelectedEnvId(envOptions[index].environment_id);
      }
    }
  }, [environmentInfo, envOptions]);

  const getEnvironments = async (companyName) => {
    try {
      setLoadingDropdownOptions(true);
      const result = await getAllEnvironment(companyName);

      if (result) {
        const envOptions = result.map((el) => ({
          ...el,
          key: el.environment_id,
          label: el.environment_name,
          value: el.environment_id,
        }));
        setEnvOptions(envOptions);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDropdownOptions(false);
    }
  };

  const handleOptionClick = (option) => {
    setSelectedEnvId(option.value);

    const newOptionObj = {
      ...option,
    };
    delete newOptionObj.key;
    delete newOptionObj.label;
    delete newOptionObj.value;
    onSelect?.(newOptionObj);
    setDropdownVisible(false);
  };

  const customDropdown = (
    <div className="dropdown-tableview-option__wrapper">
      <div className="option-thead">
        <div className="option-cell">
          <h2>Name</h2>
        </div>
        <div className="option-cell">
          <h2>BC Comp</h2>
        </div>
        <div className="option-cell">
          <h2>Type</h2>
        </div>
      </div>
      {envOptions.map((option) => (
        <div
          className={`option-rows ${
            selectedEnvId === option.value ? "selected" : ""
          }`}
          key={option.value}
          onClick={() => handleOptionClick(option)}
        >
          <div className="option-cell">{option.environment_name}</div>
          <div className="option-cell">{option.bc_company}</div>
          <div className="option-cell">{option.environment_type}</div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="environment-grid__wrapper">
      <FocusInputs.Select
        dropdownRender={() => customDropdown}
        title="Environment"
        style={{ width: "100%" }}
        value={selectedEnvId}
        open={dropdownVisible}
        onDropdownVisibleChange={(visible) => setDropdownVisible(visible)}
        loading={loadingDropdownOptions}
      >
        {envOptions.map((option) => (
          <Option key={option.value} value={option.value}>
            <span>{option.label}</span>
          </Option>
        ))}
      </FocusInputs.Select>
    </div>
  );
}

export default EnvironmentGrid;
