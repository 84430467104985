export const handleAxiosError = (error) => {
  if (error.response?.data) {
    console.error(error.response);
    throw error.response.data;
  }

  if (error.request) {
    // Request made, no response
    console.error(error.request);
    throw new Error(`No response received`);
  }

  if (error.isAxiosError) {
    console.error(error);
    throw new Error("Axios Error, Please Contact IT!");
  }
  throw error.message;
};
