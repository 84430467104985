import React from "react";
import "./permissionDenied.styles.css";

const PermissionDenied = () => {
  return (
      <div className="message">
        <h2>Permission Denied</h2>
        <p>Sorry, you do not have permission to access applications.</p>
        <p>Please contact your administrator for assistance.</p>
      </div>
  );
};

export default PermissionDenied;
