import React from "react";
import { useMemo, useState, } from "react";
import { FocusInputs, Button } from "@alpaca/framework";

export function ForgotPasswordEmailComponent(props) {
  const [email, setEmail] = useState("");
  const [emailFocus, setEmailFocus] = useState(false);
  const validEmail = useMemo(
    () =>
      email
        ? /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
        : true,
    [email]
  );
  const submitReset = ()=>{
    props.onBtnClick({email_address:email})
  }
  return (
    <>
      <div className="inputContainer">
      <p
      id="emailId"
      className={
        emailFocus && !!email && !validEmail
          ? "instructions"
          : "offscreen"
      }
    >
      Please enter a valid email
    </p>
      <FocusInputs.Input 
        title="Enter Your Email" 
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        type="text"
        id="email"
        autoComplete="off"
        onFocus={() => setEmailFocus(true)}
        onBlur={() => setEmailFocus(false)}
        className={
          emailFocus && !!email && !validEmail ? "border-red" : ""
        }
        required/>
        <Button type="primary" loading={false} className="forgotPasswordBtn"  onClick={submitReset}>
          Reset Password
        </Button>
      </div>
      <p className="body-text">
        Don’t have an email?&nbsp;
        <span onClick={props.onClick} className="clickHere">
          Click here
        </span>
      </p>
    </>
  );
}
