import axios from "axios";
import config from "../../config";
import { handleAxiosError } from "../error.handler.service";
async function register(obj) {
  return axios
    .post(`${config.IP_ADDRESS}/profile/authorization/register`, {
      user: obj.username,
      email: obj.email,
      companyName: obj.company,
      companyType: obj.companyType,
      firstName: obj.name?.firstName,
      lastName: obj.name?.lastName,
      pwd: obj.password,
    })
    .then((res) => res.data)
    .catch((error) => handleAxiosError(error));
}

export default register;
