import Cookies from "js-cookie";
import loginImg from "../../assets/Img/LoginImg.svg";
import alpacaLogoImg from "../../assets/AlpacaLogo/AlpacaHorizontalBlack.svg";
import companyImg from "../../assets/AlpacaLogo/mark_.jpg";
import React, { Component } from "react";
import { readCookie } from "@alpaca/auth_frontend";
import LoginForm from "./login-form/login-form.component";
import { connect } from "react-redux";
import withRouter from "../../helpers/withRouter";
import "./login.scss";
import { FocusInputs, Select, Icon, Button, Toast } from "@alpaca/framework";
import { getCompanyLogos } from "../../service/company/company";
import { aliasService } from "../../service/aliasService";
import { ClassSelector } from "../../store/reduxSelector";
class Login extends Component {
  constructor(props) {
    super();
    this.state = {
      companyListWithLogo: props.companyList?.map((ele) => {
        return {
          company_name: ele,
          logo_small: companyImg,
        };
      }),
      currentSelectedCompany: props.loginLocation || "",
    };
    this.toastRef = React.createRef();
  }
  componentDidMount() {
    const cookie = readCookie();
    if (cookie) {
      // why do we check if we have a cookie? because you can have a cookie but no user redux
      const redux = this.props.userInfo;
      if (
        redux?.company_name &&
        redux?.username &&
        redux?.firstName &&
        redux?.lastName
      ) {
        //redux is good enough! send them back in!
        this.timer = setInterval(() => this.props.navigate("/dashboard"));
      }
    }
    this.getAllData();
  }

  getAllData = async () => {
    await this.getSelectedCompanyName();
    await this.getCompanyLogos();
  };
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  async getSelectedCompanyName() {
    aliasService(this.props.loginLocation || null)
      .then((res) => {
        if (res) {
          this.props.changeCompany(res);
          this.setState({ currentSelectedCompany: res });
        }
      })
      .catch((error) => {
        this.toastRef?.current?.open({
          title: `Get Company Logo Error`,
          description: error.message,
          duration: 5,
          status: "error",
        });
      });
  }
  async getCompanyLogos() {
    //console.log(this.props.companyList);
    getCompanyLogos(this.props.companyList)
      .then((res) => {
        if (res && Array.isArray(res)) {
          const companyList = res.map((ele) => {
            return {
              company_name: ele.company_name,
              logo_small: ele.logo_small || companyImg,
            };
          });
          const matchedCompany = companyList.find(
            (ele) =>
              ele.company_name.toLowerCase() ===
              this.state.currentSelectedCompany.toLowerCase()
          );
          if (matchedCompany) {
            this.props.changeCompany(matchedCompany.company_name);
          }
          this.setState({
            companyListWithLogo: companyList,
            currentSelectedCompany: matchedCompany
              ? matchedCompany.company_name
              : this.state.currentSelectedCompany,
          });
        }
      })
      .catch((error) => {
        this.toastRef?.current?.open({
          title: `Get Company Logo Error`,
          description: error.message,
          duration: 5,
          status: "error",
        });
      });
  }

  removeCompany = (companyName) => {
    const newCompanyList = this.state.companyListWithLogo.filter(
      (ele) => ele.company_name !== companyName
    );
    this.removeFromCookie(companyName);
    if (this.state.currentSelectedCompany === companyName) {
      this.setState({
        currentSelectedCompany: null,
      });
    }
    this.setState({
      companyListWithLogo: newCompanyList,
    });
  };

  removeFromCookie = (company_name) => {
    const prevList = JSON.parse(Cookies.get("pulseLoggedInCompanies") || "[]");
    const newList = prevList.filter((ele) => ele !== company_name);
    Cookies.set("pulseLoggedInCompanies", JSON.stringify(newList));
  };

  render() {
    return (
      <div className="login">
        <Toast ref={this.toastRef} />
        <img
          src={alpacaLogoImg}
          alt="Alpaca Systems Inc. Logo"
          className="alpaca-logo"
        />
        <section className="graphic">
          <img src={loginImg} alt="Login Graphic" />
        </section>
        <section className="form">
          <h1 className="title mb20">Manufacturing Suite</h1>
          {/* <span className="company-text">{this.props.loginLocation}</span> */}
          <FocusInputs.Select
            optionLabelProp="label"
            value={this.state.currentSelectedCompany}
            className="company-selector-outer"
            onChange={(e) => {
              this.props.changeCompany(e);
              this.setState({ currentSelectedCompany: e });
            }}
          >
            {this.state.companyListWithLogo.map((ele, index) => {
              return (
                <Select.Option
                  key={index}
                  value={ele.company_name}
                  label={
                    <div className="company-mark">
                      <img
                        src={ele.logo_small}
                        alt="Company Logo"
                        className="company-selector"
                        onError={(e) => {
                          e.target.src = companyImg;
                        }}
                      />
                      <span className="company-text">{ele.company_name}</span>
                    </div>
                  }
                >
                  <div className="company-mark">
                    <img
                      src={ele.logo_small}
                      alt="Company Logo"
                      className="company-selector"
                      onError={(e) => {
                        e.target.src = companyImg;
                      }}
                    />
                    <span className="company-text">{ele.company_name}</span>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        this.removeCompany(ele.company_name);
                      }}
                    >
                      <Icon icon="x" />
                    </Button>
                  </div>
                </Select.Option>
              );
            })}
          </FocusInputs.Select>
          <LoginForm selectedCompany={this.state.currentSelectedCompany} />
        </section>
      </div>
    );
  }
}

const stateList = ["userInfo"];
export default connect((state) => ClassSelector(state, stateList))(
  withRouter(Login)
);
