import React from "react";
import { useState } from "react";
import { FocusInputs, Button } from "@alpaca/framework";

function ForgotPasswordUserName(props) {
  const [username, setUsername] = useState("");
  //const [company, setCompany] = useState("");
  return (
    <>
      <div className="inputContainer">
        <FocusInputs.Input
          title="Enter Your Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          type="text"
          id="username"
          autoComplete="off"
          required
        />
        {/*<FocusInputs.Input
          title="Enter Your Company"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
          type="text"
          id="company"
          autoComplete="off"
          required
  />*/}
        <Button
          type="primary"
          loading={false}
          className="forgotPasswordBtn"
          onClick={() => props.onBtnClick({ profile_name: username })}
        >
          Reset Password
        </Button>
      </div>

      <p className="body-text">
        Rather use your user name?&nbsp;
        <span onClick={props.onClick} className="clickHere">
          Click here
        </span>
      </p>
    </>
  );
}

export default ForgotPasswordUserName;
