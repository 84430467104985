import React from "react";
import { Modal, Form } from "@alpaca/framework";

export default function EditDeviceModal({ open, onCancel, ...props }) {
  return (
    <Modal {...props} title="Edit Device" open={open} onCancel={onCancel}>
      <Form></Form>
    </Modal>
  );
}
