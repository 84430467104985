import { React, useEffect } from "react";
import { FocusInputs, Form, Button, Toast } from "@alpaca/framework";
import { useRef, useState } from "react";
import { getWideLogoService } from "../../service/VisualChanges/visualChanges";
import HorizontalLogo from "../../assets/AlpacaLogo/HorizontalLogo.png";
import {
  getAuthenticatorService,
  regenerateAuthenticatorService,
} from "../../service/MFA/mfa";
function Authenticator(props) {
  const [wideLogo, setWideLogo] = useState(HorizontalLogo);
  const [authenticatorQRCode, setAuthenticatorQRCode] = useState(null);
  const [form] = Form.useForm();
  const password = Form.useWatch("Password", form);
  const toastRef = useRef(null);

  useEffect(() => {
    getWideLogoService(props?.userInfo?.company_name)
      .then((result) => {
        if (result) {
          setWideLogo(result);
        }
      })
      .catch((error) => {
        toastRef.current?.open({
          title: "Get Company Logo failed",
          description: error.message,
          duration: 3,
          status: "error",
        });
      });
  }, []);
  const verifyPassword = async () => {
    try {
      const result = await getAuthenticatorService(password);
      setAuthenticatorQRCode(result.authenticator_qrcode);
    } catch (error) {
      toastRef.current?.open({
        title: "Verify password fail!",
        description: error.message,
        duration: 3,
        status: "error",
      });
    }
  };
  const regenerateAuthenticatorQRCode = async () => {
    try {
      const res = await regenerateAuthenticatorService();
      setAuthenticatorQRCode(res.authenticator_qrcode);
    } catch (error) {
      toastRef.current.open({
        title: "regenerate QR Code fail",
        description: error.message,
        duration: 3,
        status: "error",
      });
    }
  };
  return (
    <>
      <Toast ref={toastRef} />
      <section className="ResetPasswordBackground">
        <img
          src={wideLogo}
          alt="Company Logo"
          className="mb20 company-logo"
          onError={(e) => {
            e.target.src = HorizontalLogo;
          }}
        />

        {authenticatorQRCode ? (
          <>
            <Button onClick={regenerateAuthenticatorQRCode}>
              Regenerate QR Code For Authenticator
            </Button>{" "}
            <div>
              <img src={authenticatorQRCode} />
            </div>
          </>
        ) : (
          <div className="inputContainer">
            <div className="newPassword_container">
              <Form form={form} onFinish={verifyPassword}>
                <Form.Item name="Password" validateTrigger="onSubmit">
                  <FocusInputs.Password title="Password" required />
                </Form.Item>
              </Form>
              <br />
              <button
                type="primary"
                loading={false}
                className="main btn reset-password-btn"
                onClick={() => form.submit()}
              >
                Verify Password
              </button>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default Authenticator;
