import axios from "axios";
import config from "../config";
const { handleAxiosError } = require("./error.handler.service");
export const aliasService = async (alias) => {
  try {
    const resp = await axios.get(
      `${config.IP_ADDRESS}/company/authorization/alias`,
      {
        params: { alias },
      }
    );
    return resp.data;
  } catch (err) {
    handleAxiosError(err);
  }
};
