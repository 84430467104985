import axios from "axios";
import config from "../../config";
const ROUTER_NAME = "mfa";
const { handleAxiosError } = require("../error.handler.service");
export const CheckCompanyService = async (company) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/authorization/checkCompany`,
      { companyAlias: company }
    );
    return resp.data;
  } catch (err) {
    // Handle Error Here
    handleAxiosError(err);
  }
};

export const CheckProfileService = async (company, profile) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/authorization/checkProfile`,
      {
        company: company,
        profile: profile,
      }
    );
    return resp.data;
  } catch (error) {
    // Handle Error Here
    handleAxiosError(error);
  }
};

export const SendMFACodeService = async (company, profile) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/authorization/sendMFACodeEmail`,
      {
        company: company,
        profile: profile,
      }
    );

    return resp.data;
  } catch (error) {
    // Handle Error Here
    handleAxiosError(error);
  }
};

export const MFALoginService = async (company, profile, password) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/authorization/MFALogin`,
      {
        company: company,
        profile: profile,
        password: password,
      }
    );
    return resp.data;
  } catch (error) {
    // Handle Error Here
    handleAxiosError(error);
  }
};

export const CheckAuthenticatorService = async (company, profile) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/authorization/CheckAuthenticator`,
      {
        company: company,
        profile: profile,
      }
    );
    return resp.data;
  } catch (error) {
    // Handle Error Here
    handleAxiosError(error);
  }
};

export const MFAVerifyService = async (company, profile, mfaCode, method) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/authorization/verifyMFACode`,
      {
        company: company,
        profile: profile,
        mfaCode: mfaCode,
        method: method,
      }
    );
    return resp.data;
  } catch (error) {
    // Handle Error Here
    handleAxiosError(error);
  }
};

export const getAuthenticatorService = async (password) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/getAuthenticator`,
      {
        password: password,
      }
    );
    return resp.data;
  } catch (error) {
    // Handle Error Here
    handleAxiosError(error);
  }
};

export const regenerateAuthenticatorService = async () => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/reGenerateAuthenticator`
    );
    return resp.data;
  } catch (error) {
    // Handle Error Here
    handleAxiosError(error);
  }
};
