import React, { Component } from "react";
import axios from "axios";
import {
  Table,
  Button,
  TableHeader,
  Modal,
  StatusBadge,
  Icon,
} from "@alpaca/framework";
import "./userTable.styles.scss";
import { Toast } from "@alpaca/framework";
import Settings from "../settings/settings.component";
import UserAvatar from "../../../components/avatar/userAvatar.component";
import EditInvitationButton from "../../../components/editInvitation/editInvitation.component";
import EditButton from "../../../components/editUserButton/editUserButton.component";
import UserDelete from "../../../components/userDelete/userDelete.components";
import UserWindow from "../../../components/userUpdate/userUpdate.components";
import InvitationWindow from "../../../components/invitationWindow/invitationWindow.component";
import config from "../../../config";
import { checkPermission } from "../../../service/permission/permission";
// import InviteUser from "../../../components/inviteUser/inviteUser.component";
import InviteUser from "../../../components/invite/invite.component";
import { connect } from "react-redux";
import { ClassSelector } from "../../../store/reduxSelector";
import _ from "lodash";
import { sieveObjectByKeys } from "../../../utils/sieveObject";
import Highlighter from "../../../components/highlighter/highlighter.component";
import { getAllProfile } from "../../../service/profile/profile";
class UserTable extends Component {
  state = {
    expandStatus: true,
    users: [],
  };
  updateUserTable = (updatedUsers) => {
    this.setState({ users: updatedUsers });
  };
  getAllProfiles = () => {
    getAllProfile(this.props?.userInfo?.company_name)
      .then((result) => {
        this.setState({ users: result });
      })
      .catch((error) => {
        this.toastRef.current.open({
          title: "Get User List Failed",
          description: error.message,
          duration: 5,
          status: "error",
        });
      });
  };

  constructor(props) {
    super(props);
    this.state = {
      showUpdate: false,
      showDelete: false,
      showCancel: false,
      showResend: false,
      showForceCreate: false,
      showModal: false,
      currentRecord: null,
      searchUser: "",
    };
    this.toastRef = React.createRef();
  }
  componentDidMount() {
    this.getAllProfiles();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.users !== this.state.users) {
      //this.getAllProfiles();
    }
  }

  userTypeRender(userType) {
    return (
      <select value={userType} class="user-select">
        <option value="admin">Admin</option>
        <option value="member">Member</option>
        <option value="viewer">Viewer</option>
      </select>
    );
  }

  statusRender(status) {
    return status === "active" ? (
      <StatusBadge name="ACTIVE" />
    ) : (
      <StatusBadge name="INACTIVE" />
    );
  }

  activeRender(datetimeLastActive) {
    return new Date(datetimeLastActive).toLocaleDateString("default", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
    });
  }

  joinedRender(datetimeCreated) {
    return new Date(datetimeCreated).toLocaleDateString("default", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  }

  avatarRender(name) {
    return (
      <div className="user-avatar-container">
        <UserAvatar name={name} />
        <p>{name}</p>
      </div>
    );
  }
  openModal = () => {
    this.setState({ showModal: true });
  };
  onSend = () => {
    this.toastRef.current.open({
      title: "Record inserted",
      description: "your record has been inserted successfully",
      duration: 3,
      status: "success",
    });
    this.setState({ showModal: false });
  };

  updateUser = (updatedUser) => {
    const _users = _.cloneDeep(this.state.users);
    const userIdx = _users.findIndex(
      (_user) =>
        _user.profile_name === updatedUser.profile_name &&
        _user.company_name === updatedUser.company_name
    );
    if (userIdx !== -1) {
      _users[userIdx] = {
        ..._users[userIdx],
        ...updatedUser,
      };
      this.setState({ users: _users });
    }
  };

  removeUser = (user) => {
    const _users = _.cloneDeep(this.state.users);
    const userIdx = _users.findIndex(
      (_user) =>
        _user.profile_name === user.profile_name &&
        _user.company_name === user.company_name
    );
    if (userIdx !== -1) {
      _users.splice(userIdx, 1);
      this.setState({ users: _users });
    }
  };

  cancelInvitation = (invitation) => {
    const _users = _.cloneDeep(this.state.users);
    const userIdx = _users.findIndex(
      (_user) =>
        _user.email_address === invitation.email_address &&
        _user.company_name === invitation.company_name &&
        !_user.profile_name
    );
    if (userIdx !== -1) {
      _users.splice(userIdx, 1);
      this.setState({ users: _users });
    }
  };
  resendInvitation = (invitation) => {
    const _users = _.cloneDeep(this.state.users);
    const userIdx = _users.findIndex(
      (_user) =>
        _user.email_address === invitation.email_address &&
        _user.company_name === invitation.company_name &&
        !_user.profile_name
    );
    if (userIdx !== -1) {
      _users[userIdx] = {
        ..._users[userIdx],
        ...invitation,
      };
      this.setState({ users: _users });
    }
  };
  forceCreateInvitation = (invitation) => {
    const _users = _.cloneDeep(this.state.users);
    const userIdx = _users.findIndex(
      (_user) =>
        _user.email_address === invitation.email_address &&
        _user.company_name === invitation.company_name &&
        !_user.profile_name
    );
    if (userIdx !== -1) {
      _users[userIdx] = {
        ..._users[userIdx],
        ...invitation,
      };
      this.setState({ users: _users });
    }
  };

  sendInvitation = (addData) => {
    const _users = _.cloneDeep(this.state.users);
    if (Array.isArray(addData)) {
      //const emailList = invitationEmailList.split("\n");
      addData.forEach((ele) => {
        if (ele !== "") {
          const userIdx = _users.findIndex(
            (_user) =>
              _user.email_address === ele &&
              _user.company_name === this.props?.userInfo?.company_name &&
              !_user.profile_name
          );
          if (userIdx === -1) {
            _users.push({
              email_address: ele,
              company_name: this.props?.userInfo?.company_name || "",
              profile_status: "Invite",
              invited: this.props?.userInfo?.profile_name || "",
            });
          } else {
            _users[userIdx] = {
              ..._users[userIdx],
              invited: this.props?.userInfo?.profile_name || "",
            };
          }
        }
      });
    } else {
      const userIdx = _users.findIndex(
        (_user) =>
          _user.profile_name === addData.username &&
          _user.company_name === this.props?.userInfo?.company_name
      );
      if (userIdx === -1) {
        _users.push({
          email_address: addData.email,
          company_name: this.props?.userInfo?.company_name || "",
          first_name: addData.firstName,
          last_name: addData.lastName,
          profile_name: addData.username,
          profile_status: "Active",
        });
      }
    }

    this.setState({ users: _users });
  };

  onFinish = ({ toast }) => {
    this.toastRef.current.open(toast);
    if (toast.status === "success") {
      this.setState({
        showUpdate: false,
        showDelete: false,
        showCancel: false,
        showResend: false,
        showForceCreate: false,
      });
    }
  };

  render() {
    const uniqueCompanies = [
      ...new Set(this.state.users?.map((item) => item?.company_name)),
    ];
    let columns = [
      {
        title: "Profile Name",
        dataIndex: "profile_name",
        key: "profile_name",
        sorter: (a, b) => a.profile_name?.localeCompare(b.profile_name),
        sortDirections: ["descend", "ascend"],
        render: (content) => (
          <Highlighter highlight={this.state.searchUser}>{content}</Highlighter>
        ),
      },
      {
        title: "First Name",
        dataIndex: "first_name",
        key: "first_name",
        sorter: (a, b) => a.first_name?.localeCompare(b.first_name),
        sortDirections: ["descend", "ascend"],
        render: (content) => (
          <Highlighter highlight={this.state.searchUser}>{content}</Highlighter>
        ),
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        key: "last_name",
        sorter: (a, b) => a.last_name?.localeCompare(b.last_name),
        sortDirections: ["descend", "ascend"],
        render: (content) => (
          <Highlighter highlight={this.state.searchUser}>{content}</Highlighter>
        ),
      },
      {
        title: "Email",
        dataIndex: "email_address",
        key: "email_address",
        sorter: (a, b) => a.email_address?.localeCompare(b.email_address),
        sortDirections: ["descend", "ascend"],
        render: (content) => (
          <Highlighter highlight={this.state.searchUser}>{content}</Highlighter>
        ),
      },
      {
        title: "Invited By",
        dataIndex: "invited",
        key: "invited",
        sorter: (a, b) => a.invited?.localeCompare(b.invited),
        sortDirections: ["descend", "ascend"],
        onFilter: (value, record) => record.invited.includes(value),
      },
      {
        title: "Status",
        dataIndex: "profile_status",
        key: "profile_status",
        sorter: (a, b) => a.profile_status?.localeCompare(b.profile_status),
        sortDirections: ["descend", "ascend"],
        render: (text, record) => (
          <StatusBadge
            className="status-badge"
            icon={createStatusIcon(text.toLowerCase())}
            name={text ? text : "NO STATUS"}
            color={createStatusColor(text.toLowerCase())}
          />
        ),
      },
      ...(checkPermission("modify user")
        ? [
            {
              title: <span>Actions</span>, //without filter, the font is bolded
              dataIndex: "Edit",
              key: "dataSource",
              sortable: false,
              filterable: false,
              render: (_, record, index) =>
                record.profile_status === "Invite" ? (
                  <EditInvitationButton
                    showCancelModal={() =>
                      this.setState({
                        showCancel: true,
                        currentRecord: record,
                      })
                    }
                    showResendModal={() =>
                      this.setState({
                        showResend: true,
                        currentRecord: record,
                      })
                    }
                    showForceCreateModal={() =>
                      this.setState({
                        showForceCreate: true,
                        currentRecord: record,
                      })
                    }
                  ></EditInvitationButton>
                ) : (
                  <EditButton
                    showEditModal={() =>
                      this.setState({
                        showUpdate: true,
                        currentRecord: record,
                      })
                    }
                    showDeleteModal={() =>
                      this.setState({
                        showDelete: true,
                        currentRecord: record,
                      })
                    }
                  />
                ),
            },
          ]
        : []),
    ];

    function createStatusColor(status) {
      switch (status) {
        case "inactive":
          return "#ec2027";
        case "active":
          return "#8cc640";
        default:
          return "#8c8c8c";
      }
    }

    function createStatusIcon(status) {
      switch (status) {
        case "inactive":
          return <Icon icon="close" />;
        case "active":
          return <Icon icon="check" />;
        default:
          return <Icon icon="question" />;
      }
    }

    const visibleUser = sieveObjectByKeys(
      this.state.users,
      this.state.searchUser,
      ["profile_name", "first_name", "last_name", "email_address"]
    );

    return (
      <div className="full-height">
        <Settings />
        <Toast ref={this.toastRef} />
        <div className="setting-grid">
          <div className="env-setting-grid">
            <div className="head-btn-container">
              <TableHeader
                title="User Management"
                // options={[]}
                showOptions={false}
                extraComponent={
                  checkPermission("Invite Users") ? (
                    <>
                      <button
                        className="main device-settings-btn"
                        onClick={() => this.openModal()}
                      >
                        <Icon icon="user_add" className="alttext" />
                        Invite User
                      </button>
                    </>
                  ) : (
                    <></>
                  )
                }
                onChange={(searchInput) =>
                  this.setState({ searchUser: searchInput })
                }
              />
            </div>
            <Table
              columns={columns}
              dataSource={visibleUser}
              pagination={false}
              className="settings-table"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "60px",
              }}
            >
              {/* {checkPermission("add user") ? (
              <button
                style={{
                  borderRadius: "50%",
                  width: "100px",
                  height: "100px",
                  fontSize: "14px",
                }}
                onClick={() => this.openModal()}
              >
                <ImUserPlus style={{ fontSize: "40px" }} />
                Add User
              </button>
            ) : null} */}
            </div>
          </div>

          <InviteUser
            open={this.state.showModal}
            onCancel={() => this.setState({ showModal: false })}
            sendInvitation={this.sendInvitation}
          />

          <Modal
            open={this.state.showUpdate}
            mask="black"
            width={900}
            height={750}
            onCancel={() => this.setState({ showUpdate: false })}
            title="Edit User"
            children={
              <UserWindow
                // users={users}
                updateUser={this.updateUser}
                onClose={() => this.setState({ showUpdate: false })}
                rowData={this.state.currentRecord}
                onUpdate={this.onFinish}
              />
            }
          />

          <Modal
            open={this.state.showDelete}
            mask="black"
            width={600}
            height={650}
            onCancel={() => this.setState({ showDelete: false })}
            title="Delete User"
            children={
              <UserDelete
                removeUser={this.removeUser}
                onClose={() => this.setState({ showDelete: false })}
                rowData={this.state.currentRecord}
                onDelete={this.onFinish}
              />
            }
          />
          <Modal
            open={this.state.showCancel}
            mask="black"
            width={900}
            height={400}
            onCancel={() => this.setState({ showCancel: false })}
            title="Cancel Invitation"
            children={
              <InvitationWindow
                // users={users}
                cancelInvitation={this.cancelInvitation}
                onClose={() => this.setState({ showCancel: false })}
                rowData={this.state.currentRecord}
                onFinish={this.onFinish}
              />
            }
          />
          <Modal
            open={this.state.showResend}
            mask="black"
            width={900}
            height={400}
            onCancel={() => this.setState({ showResend: false })}
            title="Resend Invitation"
            children={
              <InvitationWindow
                // users={users}
                resendInvitation={this.resendInvitation}
                onClose={() => this.setState({ showResend: false })}
                rowData={this.state.currentRecord}
                onFinish={this.onFinish}
              />
            }
          />
          <Modal
            open={this.state.showForceCreate}
            mask="black"
            width={900}
            height={750}
            onCancel={() => this.setState({ showForceCreate: false })}
            title="Force Create User"
            children={
              <InvitationWindow
                // users={users}
                forceCreateInvitation={this.forceCreateInvitation}
                onClose={() => this.setState({ showForceCreate: false })}
                rowData={this.state.currentRecord}
                onFinish={this.onFinish}
              />
            }
          />
        </div>
      </div>
    );
  }
}

const stateList = ["userInfo"];
export default connect((state) => ClassSelector(state, stateList))(UserTable);
