import "./devicesPage.styles.css";
import React, { Component } from "react";
import Tooltip from "../../../components/tooltip/tooltip.component";
import { Popover, Icon } from "@alpaca/framework";
import DisplayAppList from "./EditDevice/displayAppList.component";

export default class DeviceItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appModal: false,
    };
  }

  renderList(device) {
    const { device_name, appUsed } = device;
    const maxApps = 6;
    let appListArray = appUsed.map((app, i) => {
      if (i + 1 <= maxApps) {
        return (
          <>
            <Tooltip title={app.app_name} key={device_name + app.app_name + i}>
              <img
                src={`data:image/svg+xml;base64,${app.app_icon}`}
                alt={app.app_name}
                className="device-item-app"
              />
            </Tooltip>
          </>
        );
      }
    });
    let toReturn = [appListArray];
    if (appListArray.length >= maxApps) {
      let appsOver = appListArray.length - maxApps;
      toReturn.push(
        <>
          <Popover
            open={this.state.appModal}
            placement="right"
            trigger="click"
            content={<DisplayAppList apps={device.appUsed.slice(6)} />}
          >
            <Tooltip
              title={`${appsOver} app${appsOver > 1 ? "s" : ""}`}
              key={`${device_name}_max_length_tooltip`}
            >
              <Icon
                icon="dots_horizontal"
                onClick={() =>
                  this.setState({ appModal: !this.state.appModal })
                }
              />
            </Tooltip>
          </Popover>
        </>
      );
    }
    return toReturn;
  }

  render() {
    const device = this.props.device;
    if (device?.appUsed?.length > 0) {
      return (
        <div className="device-item-app-container">
          {this.renderList(device)}
        </div>
      );
    }
  }
}
