import "./settings.styles.css";
import React, { useState, useRef, useEffect } from "react";
import {
  Toast,
  Button,
  Form,
  FocusInputs,
  Select,
  NotificationItem,
  appMap,
  typeMap,
  Icon,
  Tooltip,
} from "@alpaca/framework";
import { getPermissionGroupsService } from "../../../service/permission/permission";
import { addNotificationService } from "../../../service/notification/notification";
import { getAllApps } from "../../../service/app/version";
import { UserInfoSelector } from "../../../store/reduxSelector";
import Settings from "./settings.component";
import { useWatch } from "antd/es/form/Form";
export function NotificationSettings({ fetchNotification }) {
  const user = UserInfoSelector();
  const [loading, setLoading] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [appOption, setAppOptions] = useState([]);
  const [sendToGroupOptions, setSendToGroupOptions] = useState([]);
  const toastRef = useRef(null);
  const [form] = Form.useForm();
  const notificationTitle = useWatch("title", form);
  const notificationApp = useWatch("app", form);
  const notificationType = useWatch("type", form);
  const notificationContent = useWatch("content", form);
  const notificationTypeOptions = [
    "info",
    "warning",
    "error",
    "success",
    "special",
  ];
  useEffect(() => {
    const fetchOptionData = async () => {
      try {
        const appResult = await getAllApps();
        const permissionGroupResult = await getPermissionGroupsService();
        const permissionGroupOption = permissionGroupResult.map(
          (ele) => ele.permission_group_name
        );
        permissionGroupOption.unshift("All");
        setAppOptions(appResult);
        setSendToGroupOptions(permissionGroupOption);
      } catch (error) {
        toastRef.current.open({
          title: "Get App List/Permission Group fail",
          description: error.message,
          duration: 3,
          status: "error",
        });
      }
    };
    fetchOptionData();
  }, []);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const onSubmit = async (value) => {
    setLoading(true);
    try {
      await addNotificationService({
        company_name: user.company_name,
        ...value,
      });
      if (fetchNotification) {
        fetchNotification();
      }
      form.resetFields();
      //to do: refresh the notification list in the right section
    } catch (error) {
      toastRef.current.open({
        title: "Add notification Fail",
        description: error.message,
        duration: 3,
        status: "error",
      });
    }

    setLoading(false);
  };

  return (
    <div className="full-height">
      <Settings />
      <Toast ref={toastRef} />
      <div className="notif-container">
        <div
          className={`notif-left-section ${
            collapsed ? "notif-left-collapsed" : ""
          }`}
        >
          <div className="head-btn-container">
            <div className="head-btn-subheader">
              <h1>Add Notification</h1>
              <p>Create alerts to send to your users.</p>
            </div>
            <button
              className="notif-collapse-button btn main"
              onClick={toggleCollapsed}
            >
              <Icon
                icon={collapsed ? "caret_left" : "caret_right"}
                className="alttext"
              />
              {collapsed ? "Expand" : "Collapse"}
            </button>
          </div>
          <Form form={form} onFinish={onSubmit}>
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
              ]}
            >
              <div className="user-settings-row">
                <FocusInputs.Input
                  title="Title"
                  className="user-input"
                  required
                />
              </div>
            </Form.Item>
            <div className="notif-row">
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please select type of notification",
                  },
                ]}
              >
                <FocusInputs.Select
                  title="Type"
                  className="user-input capitalize-inputs"
                  required
                >
                  {notificationTypeOptions.map((option) => (
                    <Select.Option
                      key={option}
                      label={option}
                      value={option}
                      className="capitalize-inputs"
                    >
                      {option}
                    </Select.Option>
                  ))}
                </FocusInputs.Select>
              </Form.Item>
              <Form.Item
                name="app"
                rules={[
                  {
                    required: true,
                    message:
                      "Please select which app can see this notification",
                  },
                ]}
              >
                <FocusInputs.Select title="App" className="user-input" required>
                  {appOption.map((option) => (
                    <Select.Option
                      key={option.app_name}
                      label={option.app_name}
                      value={option.app_name}
                    >
                      {option.app_name}
                    </Select.Option>
                  ))}
                </FocusInputs.Select>
              </Form.Item>
            </div>
            <Form.Item
              name="group"
              rules={[
                {
                  required: true,
                  message:
                    "Please select which user group will receive this notification",
                },
              ]}
            >
              <FocusInputs.Select
                title="Send To"
                className="user-input"
                required
              >
                {sendToGroupOptions.map((option) => (
                  <Select.Option key={option} label={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </FocusInputs.Select>
            </Form.Item>

            <Form.Item
              name="content"
              rules={[
                {
                  required: true,
                  message: "Please enter content",
                },
              ]}
            >
              <FocusInputs.TextArea
                title="Notification Content"
                className="user-input"
                rows={8}
                required
              />
            </Form.Item>
          </Form>
          <Button
            className="pb__ticket__button pb__button__submit"
            onClick={() => form.submit()}
            loading={loading}
          >
            Submit
          </Button>
        </div>
        <div
          className={`notif-right-section ${
            collapsed ? "notif-right-collapsed" : ""
          }`}
        >
          <NotificationItem
            notification={{
              subject: notificationTitle,
              content: notificationContent,
              app_name: notificationApp,
              notification_type: notificationType,
            }}
            className={typeMap[notificationType]?.className || ""}
            appIcon={appMap[notificationApp] || ""}
            statusIcon={typeMap[notificationType]?.icon || ""}
          />
        </div>
      </div>
    </div>
  );
}

export default NotificationSettings;
