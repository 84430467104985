import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Ticket } from "@polarbear/ticket-service-front";
import { Route, Router } from "@alpaca/framework";
import { logout } from "./store/reducer/userInfoReducer";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword.component";
import LoginPage from "./pages/login/login.component";
import MFAPage from "./pages/MFA/mfa.component";
import RegisterPage from "./pages/register/register.component";
import Invited from "./pages/login/invited.component";
import Modules from "./pages/modules/modules.component";
import UserTable from "./pages/dashboard/users/userTable.component";
import NotificationSettings from "./pages/dashboard/settings/notificationSettings.component";
import Devices from "./pages/dashboard/devices/devicesPage.component";
import NewDevices from "./pages/dashboard/devices/EditDevice/newDevice.component";
import EditDevices from "./pages/dashboard/devices/EditDevice/editDevice.component";
import CustomizationSettings from "./pages/dashboard/settings/customizationSettings.component";
import ProfileSettings from "./pages/dashboard/settings/profileSettings.component";
import EnvironmentSettings from "./pages/dashboard/settings/environmentSettings.component";
import axios from "axios";
import { readCookie } from "@alpaca/auth_frontend";
import "./App.css";
import React, { useEffect, useState } from "react";
import { setVisualSetting } from "./store/reducer/visualReducer";
import { getVisualSettingService } from "./service/VisualChanges/visualChanges";
import { getAppVersionNumber } from "./service/app/version";
import {
  getNotification,
  updateNotificationStatus,
} from "./service/notification/notification";
import { UserInfoSelector, VisualSettingSelector } from "./store/reduxSelector";
import { migrateUserDataIfNeeded } from "./store/reducer/migrateReducer";
import { checkPermission } from "./service/permission/permission";
import config from "./config";
import packageJson from "../package.json";
axios.defaults.withCredentials = true;

function App(props) {
  const location = useLocation();
  function getCompanyList() {
    const pulseLoggedInCompanies = readCookie("pulseLoggedInCompanies");
    const { pathname, origin } = location;
    let parsedPulseLoggedInCompanies;
    parsedPulseLoggedInCompanies = JSON.parse(pulseLoggedInCompanies || "[]");
    if (pathname.split("/")[1].length > 0) {
      if (
        pathname.split("/")[1] !== "forgot-password" &&
        pathname.split("/")[1] !== "dashboard" &&
        pathname.split("/")[1] !== "mfa" &&
        pathname.split("/")[1] !== "invited"
      ) {
        parsedPulseLoggedInCompanies.push(
          pathname.split("/")[1].replaceAll("%20", " ")
        );
      }
    }
    if (
      parsedPulseLoggedInCompanies &&
      Array.isArray(parsedPulseLoggedInCompanies) &&
      parsedPulseLoggedInCompanies.length > 0
    ) {
      return parsedPulseLoggedInCompanies;
    } else {
      return ["Steelhaus Technologies Inc."];
    }
  }

  function targetCompany() {
    const companies = getCompanyList();
    return companies[companies.length - 1] || "Steelhaus Technologies Inc.";
  }
  const [notificationData, setNotificationData] = useState([]);
  const user = UserInfoSelector();
  const visualSetting = VisualSettingSelector();
  const [targetedCompany, setTarget] = useState(targetCompany());
  const [companyList, setCompanyList] = useState(getCompanyList());
  const [sideBarItems] = useState([
    {
      name: "Modules",
      displayName: "Modules",
      className: "sidebar-link",
      redirect_url: "/dashboard/Modules",
    },
    {
      name: "Settings",
      displayName: "Settings",
      className: "sidebar-link",
      redirect_url: "/dashboard/Settings",
    },
    {
      name: "Devices",
      displayName: "Devices",
      className: "sidebar-link",
      redirect_url: "/dashboard/Devices",
    },
  ]);
  const navigate = useNavigate();
  const loggedIn = user ? Object.keys(user).length > 0 : false;
  const [alreadyLoggedIn, setAlreadyLoggedIn] = useState(loggedIn);

  const colors = {
    primary: visualSetting?.color_main?.split(",")[0] || "#000000",
    secondary: visualSetting?.color_secondary?.split(",")[0] || "#A51414",
    tertiary: visualSetting?.color_tertiary?.split(",")[0] || "#24c6dc",
    text: visualSetting?.color_main?.split(",")[1] || "#000000",
    alttext: visualSetting?.color_secondary?.split(",")[1] || "#FFFFFF",
  };
  const dispatch = useDispatch();
  const updateVisualRedux = (setting) => {
    // Dispatch the action using the dispatch function
    dispatch(setVisualSetting(setting));
  };

  useEffect(() => {
    fetchNotification();
    // --- Check and migrate data if user has old redux structure ---
    dispatch(migrateUserDataIfNeeded());
  }, []);

  useEffect(() => {
    if (user?.company) {
      getVisualSettingService(user.company)
        .then((res) => {
          if (res) {
            updateVisualRedux(res);
          }
        })
        .catch((error) => {});
    }
    for (const k in colors) {
      document.documentElement.style.setProperty(
        "--" + k + "-color",
        colors[k]
      );
    }
  }, [location]);

  useEffect(() => {
    for (const k in colors) {
      document.documentElement.style.setProperty(
        "--" + k + "-color",
        colors[k]
      );
    }
  }, [visualSetting]);

  useEffect(() => {
    setTarget(targetCompany());
    setCompanyList(getCompanyList());
  }, [loggedIn]);

  const fetchNotification = async () => {
    try {
      const notification = await getNotification();
      setNotificationData(notification);
    } catch (error) {}
  };
  const changeTargetCompany = (value) => {
    setTarget(value);
  };

  const userLogout = () => {
    setAlreadyLoggedIn(false);
    navigate("/");
    dispatch(logout());
  };
  const UpdateNotificationStatus = async (notification_id, status) => {
    try {
      // return;
      const updatedData = await updateNotificationStatus(
        notification_id,
        status
      );
      setNotificationData(updatedData);
    } catch (error) {}
  };

  return (
    <>
      <Router
        sideBarItems={sideBarItems}
        HelpDrawer={
          <Ticket
            axios={axios}
            apiUrl={config.TICKET_IP_ADDRESS}
            helpDeskPermission={checkPermission(
              "Solve Ticket",
              "Ticket Service"
            )}
          />
        }
        version={packageJson.version}
        user={user}
        authorized={loggedIn}
        avatarMenuItems={[{ label: "Logout", onClick: userLogout }]}
        logo={visualSetting.logo_wide}
        showWifiOption={false}
        versionCheckProps={{
          pollingInterval: 5 * 60 * 1000, // every 5 minutes
          versionNumber: packageJson.version, // current version number, should be the version number from the package.json
          fetchLatestNumber: getAppVersionNumber, // function to provide version number, as the artifact does not have access to it
        }}
        notification={{
          data: notificationData,
          update: UpdateNotificationStatus,
        }}
      >
        <Route
          path="/dashboard/Settings/Customization"
          element={<CustomizationSettings />}
        />
        <Route
          path="/dashboard/Settings/Profile"
          element={<ProfileSettings />}
        />
        <Route
          path="/dashboard/Settings/Environments"
          element={<EnvironmentSettings />}
        />
        <Route path="/dashboard/Settings/Users" element={<UserTable />} />
        <Route
          path="/dashboard/Settings/Notification"
          element={
            <NotificationSettings fetchNotification={fetchNotification} />
          }
        />
        <Route path="/dashboard/Settings" element={<CustomizationSettings />} />
        <Route path="/dashboard/Devices" element={<Devices />} />
        <Route path="/dashboard/NewDevice" element={<NewDevices />} />
        <Route path="/dashboard/EditDevice" element={<EditDevices />} />
        <Route
          path="/dashboard"
          element={<Modules autoRedirect={!alreadyLoggedIn} />}
        />
        <Route
          path="/register"
          auth={false}
          sidebar={false}
          element={<RegisterPage />}
        />
        <Route
          path="/invited"
          auth={false}
          sidebar={false}
          element={<Invited />}
        />
        <Route
          path="/forgot-password"
          auth={false}
          sidebar={false}
          element={<ForgotPassword loginLocation={targetedCompany} />}
        />
        <Route
          path="/mfa"
          auth={false}
          sidebar={false}
          element={
            <MFAPage
              companyList={companyList}
              loginLocation={targetedCompany}
              changeCompany={changeTargetCompany}
            />
          }
        />
        <Route
          path="/"
          auth={false}
          sidebar={false}
          element={
            <LoginPage
              companyList={companyList}
              loginLocation={targetedCompany}
              changeCompany={changeTargetCompany}
            />
          }
        />
      </Router>
    </>
  );
}

export default App;
