import loginImg from "../../assets/Img/LoginImg.svg";
import alpacaLogoImg from "../../assets/AlpacaLogo/AlpacaHorizontalBlack.svg";
import React, { Component } from "react";
import { readCookie } from "@alpaca/auth_frontend";
import MFAForm from "./MFA-form/mfa-form.component";
import { connect } from "react-redux";
import withRouter from "../../helpers/withRouter";
import "../login/login.scss";
import { ClassSelector } from "../../store/reduxSelector";
class mfaPage extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() {
    const cookie = readCookie();
    if (cookie) {
      // why do we check if we have a cookie? because you can have a cookie but no user redux
      const redux = this.props.userInfo;
      if (
        redux?.company_name &&
        redux?.username &&
        redux?.firstName &&
        redux?.lastName
      ) {
        //redux is good enough! send them back in!
        window.location.pathname = "/dashboard";
      }
    }
  }

  render() {
    return (
      <div className="login">
        <img
          src={alpacaLogoImg}
          alt="Alpaca Systems Inc. Logo"
          className="alpaca-logo"
        />
        <section className="graphic">
          <img src={loginImg} alt="Login Graphic" />
        </section>
        <section className="form">
          <h1 className="title mb20">Manufacturing Suite</h1>
          <MFAForm
            companyList={this.props.companyList}
            loginLocation={this.props.loginLocation}
          />
        </section>
      </div>
    );
  }
}

const stateList = ["userInfo"];
export default connect((state) => ClassSelector(state, stateList))(
  withRouter(mfaPage)
);
