import config from "../../config";
import axios from "axios";
axios.defaults.withCredentials = true;
const ROUTER_NAME = "profile";
const { handleAxiosError } = require("../error.handler.service");
export const updateProfile = async ({
  profile_name, // string
  company_name, // string
  first_name, // string
  last_name, // string
  profile_status, // string
  email_address, // string
  changedPermissions, //array of permission object
}) => {
  try {
    const response = await axios.put(`${config.IP_ADDRESS}/${ROUTER_NAME}`, {
      profile_name: profile_name,
      company_name: company_name,
      first_name: first_name,
      last_name: last_name,
      profile_status: profile_status,
      email_address: email_address,
      changedPermissions: changedPermissions,
    });

    return { status: true, message: "your changes has been updated" };
  } catch (error) {
    handleAxiosError(error);
  }
};

export const deleteProfile = async ({ profile_name, company_name }) => {
  try {
    const response = await axios.delete(`${config.IP_ADDRESS}/${ROUTER_NAME}`, {
      data: {
        profile_name: profile_name,
        company_name: company_name,
      },
    });
    return { status: true, message: "your changes has been updated" };
  } catch (error) {
    handleAxiosError(error);
  }
};

export const insertProfile = async ({
  profile_name,
  company_name,
  first_name,
  last_name,
  email_address,
  password,
  profile_status,
}) => {
  try {
    const response = await axios.post(`${config.IP_ADDRESS}/${ROUTER_NAME}`, {
      profile_name: profile_name,
      company_name: company_name,
      first_name: first_name,
      last_name: last_name,
      email_address: email_address,
      password: password,
      profile_status: profile_status,
    });

    return { status: true, message: "your changes has been updated" };
  } catch (error) {
    handleAxiosError(error);
  }
};

export const getAllProfile = async (company_name) => {
  try {
    const resp = await axios.get(`${config.IP_ADDRESS}/${ROUTER_NAME}`, {
      params: { company_name: company_name || "" },
    });
    return resp.data;
  } catch (err) {
    handleAxiosError(err);
  }
};

export const updateOwnProfile = async (data) => {
  try {
    //todo make a more consistent object so I can tell whats going into here. The backend has a spread in a update function which makes it hard to tell what goes into here.
    const response = await axios.post(
      `${config.IP_ADDRESS}/${ROUTER_NAME}/updateOwn`,
      data
    );
    return { status: true, message: "your changes has been updated" };
  } catch (error) {
    handleAxiosError(error);
  }
};
