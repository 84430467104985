import axios from "axios";
import config from "../../config";
const { handleAxiosError } = require("../error.handler.service");

export const addNotificationService = async (submitData) => {
  try {
    const resp = await axios.post(
      `${config.IP_ADDRESS}/notification/add`,
      submitData
    );
    return resp;
  } catch (error) {
    // Handle Error Here
    handleAxiosError(error);
  }
};

export const getNotification = async () => {
  return axios
    .get(`${config.IP_ADDRESS}/notification/fetch`)
    .then((result) => result?.data)
    .catch((error) => handleAxiosError(error));
};

export const updateNotificationStatus = async (notification_id, new_status) => {
  return axios
    .post(`${config.IP_ADDRESS}/notification/update`, {
      notification_id,
      notification_status: new_status,
    })
    .then((result) => result?.data)
    .catch((error) => handleAxiosError(error));
};
