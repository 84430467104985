import React from "react";
import classNames from "classnames";
import { Switch } from "@alpaca/framework";
import "./inviteOrCreateUserSwitch.scss";

export default function InviteOrCreateUserSwitch({ isCreatingUser, onChange }) {
  return (
    <div className="invite-switch mb20">
      <h2
        className={classNames({
          "invite-switch-text": true,
          "invite-switch-text-selected": !isCreatingUser,
        })}
      >
        Invite Users
      </h2>
      <Switch onChange={onChange} checked={isCreatingUser} />
      <h2
        className={classNames({
          "invite-switch-text": true,
          "invite-switch-text-selected": isCreatingUser,
        })}
      >
        Create User
      </h2>
    </div>
  );
}
