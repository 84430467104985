import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Form, FocusInputs, Icon } from "@alpaca/framework";
import classNames from "classnames";
import {
  CheckProfileService,
  SendMFACodeService,
  MFALoginService,
  CheckAuthenticatorService,
  MFAVerifyService,
} from "../../../service/MFA/mfa";
import CompanySelector from "../../../components/company/companySelector.component";
import { login } from "../../../store/reducer/userInfoReducer";
import { setVisualSetting } from "../../../store/reducer/visualReducer";
import "../../login/login-form/login-form.scss";

function MFAForm({ companyList, loginLocation }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [serverMessage, setServerMessage] = useState(false);
  const [companyName, setCompanyName] = useState(loginLocation);
  const [profileFound, setProfileFound] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [passwordVerified, setPasswordVerified] = useState(false);
  const [verifyMethod, setVerifyMethod] = useState(null);
  const profile = Form.useWatch("username", form);
  const password = Form.useWatch("password", form);
  const code = Form.useWatch("code", form);

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      // Enter
      if (passwordVerified) {
        if (!!code && !!verifyMethod) {
          mfaVerify();
        }
      } else {
        if (!!password) {
          mfaLogin();
        }
      }
    }
  };
  const changeSelectCompany = async (company) => {
    setServerError();
    setCompanyName(company);
    if (!!profile) {
      setProfileFound(false);
      try {
        const profileResultList = await CheckProfileService(company, profile);
        if (profileResultList?.length) {
          setProfileFound(true);
        } else {
          setServerError("User Not Found");
        }
      } catch (error) {
        setServerError(error.message);
      }
    }
    setInputFocused(false);
  };

  const showError = (message) => {
    setServerError(message);
  };

  const checkProfile = async () => {
    if (profile !== undefined) {
      setProfileFound(false);
      const profileResultList = await CheckProfileService(companyName, profile);
      if (profileResultList?.length) {
        setProfileFound(true);
      } else {
        setServerError("User Not Found");
      }
    }
    setInputFocused(false);
  };

  const mfaVerify = async () => {
    form.resetFields(["code"]);
    setIsLoading(true);

    if (!!code) {
      try {
        const loginResult = await MFAVerifyService(
          companyName,
          profile,
          code,
          verifyMethod
        );

        if (loginResult?.success) {
          const { visualSetting, ...userInfo } = loginResult.data.info;
          dispatch(login(userInfo));
          dispatch(setVisualSetting(visualSetting));
          navigate("/dashboard");
        } else {
          setServerError(loginResult?.description || "");
        }
      } catch (error) {
        setServerError(error.message);
      }
    }
    setIsLoading(false);
  };
  const mfaLogin = async () => {
    setIsLoading(true);
    try {
      const result = await MFALoginService(companyName, profile, password);
      if (result) {
        //log in success, need to be 2nd step
        setPasswordVerified(true);
      } else {
        setServerError("Wrong Password, Please Try Again");
        form.resetFields(["password"]);
      }
    } catch (error) {
      setServerError(error.message);
      form.resetFields(["password"]);
    }
    setIsLoading(false);
  };
  const checkAuthenticator = async () => {
    try {
      setIsLoading(true);
      const result = await CheckAuthenticatorService(companyName, profile);
      if (result) {
        setVerifyMethod("Authenticator");
      } else {
        setServerError(
          "There is no Google Authenticator Set Up, please use email to verify"
        );
      }
    } catch (error) {
      setServerError(error.description || "");
    } finally {
      setIsLoading(false);
    }
  };
  const generateCode = async () => {
    try {
      setIsLoading(true);
      const result = await SendMFACodeService(companyName, profile);
      if (result.success) {
        setServerMessage(
          `MFA code sent to ${result.email || ""}. Please check your email.`
        );
      } else {
        setServerError(result.description || "");
      }
      return;
    } catch (error) {
      setServerError(error.message);
    } finally {
      setIsLoading(false);
      setVerifyMethod("Email");
    }
  };

  const goBack = () => {
    setVerifyMethod(null);
    setServerMessage(false);
  };

  return (
    <Form
      form={form}
      onFinish={mfaLogin}
      className="login-form"
      onChange={() => setServerError()}
      onKeyUp={handleKeyUp}
    >
      <div className={classNames("validation", { show: serverError })}>
        {serverError}
      </div>

      <div className={classNames("message", { show: serverMessage })}>
        {serverMessage}
      </div>
      <div>
        <CompanySelector
          companyList={companyList}
          currentSelectedCompany={companyName}
          changeCurrentCompany={changeSelectCompany}
          showError={showError}
          disabled={isLoading || passwordVerified}
        ></CompanySelector>
      </div>
      <Form.Item name="username">
        <FocusInputs.Input
          title="Username"
          disabled={isLoading || passwordVerified}
          onBlur={checkProfile}
          onFocus={() => setInputFocused(true)}
        />
      </Form.Item>
      {!passwordVerified && (
        <Form.Item name="password">
          <FocusInputs.Password
            title="Password"
            visibilityToggle={false}
            disabled={isLoading || !profileFound}
            onChange={(e) => setServerError(false)}
          />
        </Form.Item>
      )}
      {verifyMethod && (
        <Form.Item name="code">
          <FocusInputs.Input
            title="Code"
            visibilityToggle={false}
            disabled={isLoading}
            onChange={() => setServerMessage(false)}
          />
        </Form.Item>
      )}
      {!passwordVerified && (
        <div className="btn-container">
          <Button
            loading={isLoading}
            onClick={() => form.submit()}
            className="btn main"
            disabled={!profileFound || inputFocused || !password}
            style={{ backgroundColor: "#FED208", borderColor: "#FED208" }}
          >
            Login
          </Button>
        </div>
      )}
      {passwordVerified && (
        <>
          <div className="back-button-container">
            {verifyMethod && (
              <Button className="back-button" onClick={goBack}>
                <Icon icon="back" />
              </Button>
            )}
            <div>2nd Step Verify:</div>
          </div>
          <div className="btn-container">
            {!verifyMethod ? (
              <>
                <Button
                  loading={isLoading}
                  onClick={generateCode}
                  className="btn main"
                  style={{ backgroundColor: "#FED208", borderColor: "#FED208" }}
                >
                  Email
                </Button>

                <Button
                  className="btn secondary"
                  disabled={isLoading}
                  onClick={checkAuthenticator}
                >
                  Google Authenticator
                </Button>
              </>
            ) : verifyMethod === "Authenticator" ? (
              <>
                <Button
                  loading={isLoading}
                  className="btn secondary"
                  onClick={mfaVerify}
                >
                  Verify
                </Button>
              </>
            ) : (
              <>
                <Button
                  loading={isLoading}
                  onClick={mfaVerify}
                  className="btn main"
                  style={{ backgroundColor: "#FED208", borderColor: "#FED208" }}
                >
                  Verify
                </Button>

                <Button
                  loading={isLoading}
                  className="btn secondary"
                  onClick={generateCode}
                >
                  resend
                </Button>
              </>
            )}
          </div>
        </>
      )}
    </Form>
  );
}
export default MFAForm;
