import axios from "axios";
import config from "../../config";
import { handleAxiosError } from "../error.handler.service";
export async function getCompanyTypes() {
  try {
    const response = await axios.get(
      `${config.IP_ADDRESS}/company/authorization/getTypes`
    );
    return response.data;
  } catch (err) {
    handleAxiosError(err);
  }
}
