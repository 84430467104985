import axios from "axios";
import { store } from "../../store/store";
import config from "../../config";
const { handleAxiosError } = require("../error.handler.service");
export const checkPermission = (permission_name, app_name = "Login Portal") => {
  const permissions = getUserPermission(store.getState());
  if (Array.isArray(permissions)) {
    return permissions.some(
      (ele) =>
        ele.permission_name === permission_name && ele.app_name === app_name
    );
  } else {
    return false;
  }
};

export const fetchPermissionsForUser = async (companyName, profileName) => {
  try {
    const resp = await axios.get(`${config.IP_ADDRESS}/permission/userPermissionGroup`, {
      params: {
        companyName,
        profileName,
      },
    });
    return resp;
  } catch (error) {
    // Handle Error Here
    handleAxiosError(error);
  }
};

export const getPermissionGroupsService = async () => {
  try {
    const resp = await axios.get(
      `${config.IP_ADDRESS}/permission/permissionGroup`,
    );
    return resp.data;
  } catch (error) {
    // Handle Error Here
    handleAxiosError(error);
  }
};

function getUserPermission(state) {
  return state?.userInfo?.permissions;
}
