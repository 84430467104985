import React, { useState } from "react";
import { Icon } from "@alpaca/framework";
function EditParagraph(props) {
  const [editing, setEditing] = useState(false);

  function handleEdit() {
    setEditing(true);
  }

  function handleInputBlur(value) {
    setEditing(false);
    if (props.onSave) {
      props.onSave(props.name, value.target.value);
    }
  }

  return editing && !props.cantEdit ? (
    <input
      type="text"
      className={props ? props.className : ""}
      defaultValue={props.value}
      onBlur={handleInputBlur}
    />
  ) : (
    <p className={props ? props.className : ""}>
      {props.name}:
      <span className={props ? props.className : ""}>{props.value}</span>
      {!props.cantEdit ? <Icon icon={"pencil"} onClick={handleEdit} /> : <></>}
    </p>
  );
}
export default EditParagraph;
