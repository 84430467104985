import React, { useState, useRef, useEffect } from "react";
import { Toast, Modal, Form, Loading } from "@alpaca/framework";
import { UserInfoSelector } from "../../store/reduxSelector.jsx";

import { fetchPermissionsForUser } from "../../service/permission/permission.js";
import { sendInvitationService } from "../../service/invite/invite.js";

import InviteOrCreateUserSwitch from "./inviteOrCreateUserSwitch/inviteOrCreateUserSwitch.component.jsx";
import Permissions from "../user/permission/permission.component.jsx";
import CreateUserForm from "./createUserForm/createUserForm.component.jsx";
import InviteUserForm from "./inviteUserForm/inviteUserForm.component.jsx";

import "./invite.styles.scss";

function InviteModal(props) {
  const user = UserInfoSelector();
  const toastRef = useRef(null);

  // create user form
  const [createUserForm] = Form.useForm();

  const [isSendingInvitation, setIsSendingInvitation] = useState(false);
  const [isCreatingUser, setIsCreatingUser] = useState(false);
  const [madeChanges, setMadeChanges] = useState(false);
  const [originalPermissions, setOriginalPermissions] = useState([]);

  const [permissions, setPermissions] = useState([]);
  const [newUserProfile, setNewUserProfile] = useState([]);
  const [invitationList, setInvitationList] = useState([]);

  useEffect(() => {
    const fetchPermissions = async (companyName, profileName) => {
      try {
        const res = await fetchPermissionsForUser("", "");
        const data = res.data.map((el) => ({
          ...el,
          isGranted: false,
        }));
        setOriginalPermissions(data);
        setPermissions(data);
      } catch (error) {
        toastRef.current?.open({
          title: "Get permission Failed",
          description: error.message,
          status: "error",
        });
      }
    };
    fetchPermissions();
  }, []);

  // clears data when toggling switch
  useEffect(() => {
    if (!isCreatingUser) {
      setNewUserProfile({});
    } else {
      setInvitationList([]);
    }
  }, [isCreatingUser]);

  return (
    <>
      <Toast ref={toastRef} />
      <Modal
        open={props.open}
        onCancel={onExitModal}
        title="Invite User"
        width={1000}
        height={800}
        footer={false}
        maskClosable={true}
      >
        <Loading
          spinning={isSendingInvitation}
          size="large"
          tip="Sending invitation..."
        >
          <div className="invite-user-modal">
            <div>
              <InviteOrCreateUserSwitch
                isCreatingUser={isCreatingUser}
                onChange={() => setIsCreatingUser(!isCreatingUser)}
              />
              {isCreatingUser ? (
                <CreateUserForm
                  form={createUserForm}
                  triggerReset={isCreatingUser}
                  onChange={(profile) => setNewUserProfile(profile)}
                  onCreateUser={onSendInvite}
                />
              ) : (
                <InviteUserForm
                  emails={invitationList}
                  onChange={(list) => setInvitationList(list)}
                />
              )}
            </div>
            <Permissions
              permissions={permissions}
              onChange={onChangePermission}
            />
          </div>
          <button className="btn main" onClick={onAttemptInvite}>
            Send
          </button>
        </Loading>
      </Modal>
    </>
  );

  /* methods */
  function onExitModal() {
    if (!madeChanges) {
      props.onCancel();
      setInvitationList([]);
    } else {
      //TODO - Implement Extra Layer of Modal Or perhaps a Toast to confirm! After all the user might want to keep this data!
    }
  }

  // update permissions
  function onChangePermission(index, value) {
    setPermissions((prev) => {
      const curr = JSON.parse(JSON.stringify(prev));
      curr[index].isGranted = value;
      return curr;
    });
  }

  // validate invitation requirements before sending
  function onAttemptInvite() {
    if (isCreatingUser) {
      return createUserForm.submit();
    }

    if (invitationList.length <= 0) {
      return toastRef.current.open({
        title: "Invite Failed",
        description: "You must enter at least one email to invite users.",
        status: "error",
      });
    }

    return onSendInvite();
  }

  // send invitation
  function onSendInvite() {
    setIsSendingInvitation(true);
    if (isCreatingUser) {
      sendInvitationService({
        email_list: [newUserProfile["email"]],
        company: user["company"],
        profile_name: user["profile_name"],
        message: "",
        invited_permission: JSON.stringify(permissions),
        invited_premade_profile_name: newUserProfile["username"],
        invited_premade_profile: JSON.stringify(newUserProfile),
      })
        .then(onFinishInvite(newUserProfile))
        .catch(onErrorInvite);
    } else {
      sendInvitationService({
        email_list: invitationList,
        company: user["company"],
        profile_name: user["profile_name"],
        message: "",
        invited_permission: JSON.stringify(permissions),
      })
        .then(onFinishInvite(invitationList))
        .catch(onErrorInvite);
    }
  }

  function onFinishInvite(data) {
    setIsSendingInvitation(false);
    setIsCreatingUser(false);
    setPermissions(originalPermissions);
    setNewUserProfile([]);
    setInvitationList([]);
    if (props.sendInvitation) {
      props.sendInvitation(data);
    }
    return toastRef.current.open({
      title: "Invite Completed",
      description: "Your invitation has been emailed successfully.",
      status: "success",
    });
  }

  function onErrorInvite(error) {
    setIsSendingInvitation(false);
    setIsCreatingUser(false);
    toastRef.current.open({
      title: "Invite Failed",
      description: error.message,
      status: "error",
    });
  }
}

export default InviteModal;
