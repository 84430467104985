import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { Input, Tooltip, Icon, Toast, Select, Button } from "@alpaca/framework";
import "./environmentModal.styles.scss";
import {
  getSPInfo,
  getTenantIdByDomainName,
  verifySPTenantInfo,
  saveSPEnvironment,
} from "../../../../service/environment/environment";

const SetupSPEnvironment = forwardRef(({ changeSaveStatus }, ref) => {
  const user = useSelector((state) => state.userInfo);
  const [domainName, setDomainName] = useState("");
  //const [siteName, setSiteName] = useState("");
  const [tenantInfo, setTenantInfo] = useState(null);
  const toastRef = useRef(null);
  useEffect(() => {
    getTenant();
  }, [user]);

  const getTenant = async () => {
    getSPInfo(user?.company_name)
      .then((result) => {
        setTenantInfo(result || null);
        setDomainName(result.host_name);
      })
      .catch((error) => {
        toastRef.current.open({
          title: "Can not get sharepoint info",
          description: error.message,
          duration: 3,
          status: "error",
        });
      });
  };

  const getTenantId = async () => {
    if (!domainName || !domainName.includes(".sharepoint.com")) {
      toastRef.current.open({
        title: "Wrong Domain Name!",
        description: "Please check Domain Name format!",
        duration: 3,
        status: "error",
      });
      return;
    }
    getTenantIdByDomainName(domainName.split(".sharepoint").join(""))
      .then((result) => {
        if (result.success) {
          setTenantInfo({
            ...tenantInfo,
            tenant_id: result.data,
          });
        } else {
          toastRef.current.open({
            title: "Can not get tenant id",
            description: result.data,
            duration: 3,
            status: "error",
          });
        }
      })
      .catch((error) => {
        toastRef.current.open({
          title: "Can not get tenant id",
          description: error.message,
          duration: 3,
          status: "error",
        });
      });
  };

  const verifySPTenantInfoOp = async () => {
    verifySPTenantInfo({
      ...tenantInfo,
      company_name: user?.company_name,
      host_name: domainName,
    })
      .then((result) => {
        setTenantInfo({
          ...tenantInfo,
          host_name: result,
        });
        changeSaveStatus(true);
      })
      .catch((error) => {
        toastRef.current.open({
          title: "Can not verify tenant info!",
          description: error.message,
          duration: 3,
          status: "error",
        });
      });
  };
  useImperativeHandle(ref, () => ({
    async handleSave() {
      try {
        let result = await saveSPEnvironment({
          ...tenantInfo,
          company_name: user?.company_name,
        });

        setDomainName("");
        setTenantInfo(null);

        return result;
      } catch (error) {
        toastRef.current.open({
          title: "Save fail",
          description: error.message,
          duration: 3,
          status: "error",
        });
      }
    },
  }));

  return (
    <>
      <Toast ref={toastRef} />
      <div className="create-env-container">
        <div className="create-env-input-btn-type">
          <h3>Domain Name:</h3>
          <Input
            disabled={!!tenantInfo?.host_name}
            className="create-env-input-main"
            dropdownStyle={{ height: "auto" }}
            style={{
              width: "100%",
            }}
            required
            value={domainName}
            onChange={(e) => setDomainName(e.target.value)}
            placeholder="xxx.sharepoint.com"
            size="large"
          ></Input>
          <Button
            className="btn secondary"
            onClick={getTenantId}
            disabled={!!tenantInfo?.host_name}
          >
            Get Tenant ID
          </Button>
        </div>
        <div className="create-env-input-btn">
          <p className="subtext">Tenant ID:</p>
          <p className="subtext">
            {tenantInfo?.tenant_id || "####-####-####-####"}
          </p>
        </div>
        <div className="create-env-input-btn-special">
          <h3>Client ID:</h3>
          <Input
            required
            disabled={!!tenantInfo?.host_name}
            className="create-env-input-main"
            placeholder="ID"
            value={tenantInfo?.client_id || ""}
            onChange={(e) =>
              setTenantInfo({
                ...tenantInfo,
                client_id: e.target.value,
              })
            }
            style={{
              width: "100%",
            }}
            size="large"
          ></Input>
          <Tooltip
            title={
              <p className="white-tooltip">
                Link to <span className="link-text">Tutorial</span>
              </p>
            }
            placement="top"
            zIndex={99999}
          >
            <Icon icon="question" className="help-circle" />
          </Tooltip>
        </div>
        <div className="create-env-input-btn-type">
          <h3>Client Secret:</h3>
          <Input
            required
            disabled={!!tenantInfo?.host_name}
            className="create-env-input-main"
            placeholder="Secret"
            style={{
              width: "100%",
            }}
            value={tenantInfo?.client_secret || ""}
            onChange={(e) =>
              setTenantInfo({
                ...tenantInfo,
                client_secret: e.target.value,
              })
            }
            size="large"
          ></Input>
          <Button
            className="btn secondary"
            onClick={verifySPTenantInfoOp}
            disabled={!!tenantInfo?.host_name}
          >
            Verify Tenant Info
          </Button>
        </div>
        {/*<div className="create-env-input-btn-type">
          <h3>Site Name:</h3>
          <Input
            prefix="https://"
            disabled={!!tenantInfo?.site_id}
            className="create-env-input-main"
            dropdownStyle={{ height: "auto" }}
            style={{
              width: "100%",
            }}
            value={siteName}
            onChange={(e) => setSiteName(e.target.value)}
            placeholder="xxx.sharepoint.com/sites/xxx"
            size="large"
          ></Input>
         
          </div>
        <div className="create-env-input-btn">
          <p className="subtext">Site ID:</p>
          <p className="subtext">
            {tenantInfo?.site_id || "####-####-####-####"}
          </p>
        </div>
        */}
      </div>
    </>
  );
});

export default SetupSPEnvironment;
