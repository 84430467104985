import React from "react";
import { FocusInputs, Form, Button } from "@alpaca/framework";
import { useState, useMemo } from "react";
import CompanyLogo from "../../../assets/AlpacaLogo/AlpacaHorizontalBlack.svg";
import "./newPassword.styles.scss";
import validator from "../../../pages/register/components/register-form/register-form.validators";
function NewPassword(props) {
  const [form] = Form.useForm();
  const newPassword = Form.useWatch("New Password", form);
  const handleResetPassword = (values) => {
    props.onBtnClick(newPassword);
  };
  return (
    <>
      <section className="forgotPasswordBackground">
        <img src={CompanyLogo} className="login-logo" />
        <div className="inputContainer">
          <div className="newPassword_container">
            <Form form={form} onFinish={handleResetPassword}>
              <Form.Item
                name="New Password"
                rules={validator.password}
                validateTrigger="onSubmit"
              >
                <FocusInputs.Password title="New Password" required />
              </Form.Item>
              <Form.Item
                name="Confirm Password"
                rules={validator.confirmPassword(newPassword)}
                validateTrigger="onSubmit"
              >
                <FocusInputs.Password title="Confirm Password" required />
              </Form.Item>
            </Form>
            <br />
            <Button
              type="primary"
              loading={false}
              className="forgotPasswordBtn"
              onClick={() => form.submit()}
            >
              Reset Password
            </Button>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewPassword;
